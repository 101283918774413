import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { variables } from '../Variables';
import { DTable } from './DTable';
import { Dropdown, Row, Col, Alert, Container, Form, Button, InputGroup, Toast, ToastContainer } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons'
import { Export } from './Export';
import { DbContext } from '../contexts/DbContext';
import * as XLSX from 'xlsx';

const MAX_POLLING_ATTEMPTS = 60; // 60 secondi massimo

export function DbNavigator(props) {
    const { selectedDb, setSelectedDb } = useContext(DbContext);

    // Creiamo una referenza stabile per executeQuery
    const executeQueryRef = useRef(null);
    
    // Inizializzazione dello stato del componente usando useState
    const [state, setState] = useState(() => {
        console.log("DbNavigator initializing state, props:", props);
        
        // Stato iniziale
        const initialState = {
            result: [{ 'Type a field to get started': '-' }],
            infos: [],  // Lista delle informazioni disponibili
            info: -1,   // ID dell'informazione selezionata
            dbName: 'Choose a DB', // Nome del database selezionato
            selectedInfo: 'Select query',  // Testo visualizzato nel dropdown
            filter: '',  // Filtro per la query
            animation: false,  // Flag per l'animazione di caricamento
            data: {},  // Dati per l'esportazione
            refresh: false,  // Flag per il refresh
            param: '',  // Parametro per la query
            showToast: false,  // Flag per mostrare/nascondere il toast
            toastContent: '',  // Contenuto del toast
            toastbg: 'info',  // Colore di sfondo del toast
            selectedInfoTooltip: '',  // Nuovo stato per il tooltip
            pollingAttempts: 0,
        };

        // Carica lo stato salvato dal localStorage, se disponibile
        const savedState = JSON.parse(localStorage.getItem('pagesDbNav'));
        if (savedState && savedState[props.id]) {
            initialState.info = savedState[props.id].info;
            initialState.filter = savedState[props.id].filter;
        }

        console.log("Initial state:", initialState);
        return initialState;
    });

    // Funzione di esportazione dell'excel
    const exportToExcel = useCallback(() => {
        const queryName = state.selectedInfo || 'Query';
        const searchField = state.param || 'All';
        const fileName = `${queryName}_campo_${searchField}.xlsx`;

        // Assicurati che state.data sia l'array di oggetti che vuoi esportare
        const ws = XLSX.utils.json_to_sheet(state.data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    
        // Genera e scarica il file
        XLSX.writeFile(wb, fileName);
    }, [state.selectedInfo, state.param, state.data]);

    // Effetto per gestire il montaggio del componente e gli aggiornamenti del database selezionato
    useEffect(() => {
        console.log("DbNavigator: useEffect - current context:", { selectedDb });
        console.log("DbNavigator: Current state before updateStateFromContext:", state);
        if (selectedDb && selectedDb.id !== -1) {
            updateStateFromContext();
        }
    }, [selectedDb]);

    // Funzione per aggiornare lo stato dal contesto
    const updateStateFromContext = useCallback(() => {
        console.log("DbNavigator: updateStateFromContext - selectedDb:", selectedDb);
        if (selectedDb && selectedDb.id !== -1) {
            setState(prevState => ({
                ...prevState,
                dbName: selectedDb.name
            }));
            refreshList(true);
        }
    }, [selectedDb]);

    // Funzione per controllare la visibilità del toast
    const setShow = useCallback((show) => {
        setState(prevState => ({ ...prevState, showToast: show }));
    }, []);

    // Funzione per mostrare un messaggio toast
    const showToast = useCallback((content, bgColor) => {
        setState(prevState => ({
            ...prevState,
            showToast: true, 
            toastContent: content,
            toastbg: bgColor
        }));
    }, []);

    // Funzione per aggiornare la lista delle informazioni e eseguire query
    const refreshList = useCallback((all = true) => {
        console.log("refreshList called with all:", all);
        console.log("Current state before refresh:", state);

        if (selectedDb && selectedDb.id !== -1) {
            // Fetch delle informazioni disponibili
            // L'endpoint home si presenta al server dando l'informazione del suo nome
            const apiUrl = '/api/info?component=dbnavigator';
            console.log("Calling API URL for info:", apiUrl);
            fetch(apiUrl, { 
                method: 'GET',
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {       
                console.log("Parsed API response for info:", data);
    
                if (Array.isArray(data) && data.length > 0) {
                    setState(prevState => ({ ...prevState, infos: data }));
                } else {
                    console.warn("Received empty or invalid data for infos");
                    setState(prevState => ({
                        ...prevState,
                        infos: [],
                        showToast: true,
                        toastContent: 'No information available for this database. Please check with your administrator.',
                        toastbg: 'warning'
                    }));
                }
            })
            .catch(error => {
                console.error("Error fetching infos:", error);
                setState(prevState => ({
                    ...prevState,
                    infos: [],
                    showToast: true,
                    toastContent: 'Error retrieving information. Check your network connection and try again.',
                    toastbg: 'danger'
                }));
            });

            // Non eseguiamo la query qui, ma solo quando un'informazione specifica viene selezionata
        } else {
            console.warn("No database selected. Skipping refresh.");
        }
    }, [selectedDb, state]); 

    // Funzione per ottenere le chiavi del risultato
    const getKeys = useCallback(() => {
        return state.result.length > 0 ? Object.keys(state.result[0]) : [''];
    }, [state.result]);

    // Funzione per aggiornare i dati di download
    const getDownloadData = useCallback((downloadData) => {
        if (downloadData !== state.data) {
            setState(prevState => ({ ...prevState, data: downloadData }));
        }
    }, [state.data]);

    // Funzione per gestire la selezione di un'informazione
    const handleInfoSelection = useCallback((info) => {
        console.log("Selected info:", info);
        setState(prevState => ({
            ...prevState,
            info: info.Id,
            selectedInfo: info.name,
            selectedInfoTooltip: info.tooltip,  // Aggiorna il tooltip
            filter: info.query
        }));
        if (props.changeName) {
            props.changeName(info.name, props.id);
        }
        // Rimossa la chiamata automatica a executeQuery
    }, [props]);

    const executeQuery = useCallback((infoId) => {
        if (selectedDb && selectedDb.id !== -1 && infoId !== -1) {
            setState(prevState => ({ ...prevState, animation: true }));
    
            const MAX_POLLING_ATTEMPTS = 30; // 30 secondi massimo
    
            // Funzione per il polling dello stato della query
            const pollQueryStatus = (requestId, attempt = 0) => {
                if (attempt >= MAX_POLLING_ATTEMPTS) {
                    console.error("Polling timeout reached");
                    showToast('Query took too long to complete', 'warning');
                    setState(prevState => ({ ...prevState, animation: false }));
                    return;
                }
            
                console.log(`Polling attempt ${attempt + 1} for request ${requestId}`);
                
                fetch(`/api/query-status/${requestId}/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('token')}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                .then(async response => {
                    console.log("Poll response status:", response.status);
                    const text = await response.text();
                    console.log("Raw response text:", text);
            
                    try {
                        const data = JSON.parse(text);
                        
                        // Se abbiamo un errore dal server, lo gestiamo
                        if (data.error) {
                            console.error("Server error:", data.error);
                            showToast(data.error, 'danger');
                            setState(prevState => ({ ...prevState, animation: false }));
                            return;
                        }
            
                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }
            
                        if (data.status === 'completed') {
                            console.log("Query completed, results:", data.result);
                            setState(prevState => ({
                                ...prevState,
                                result: data.result,
                                animation: false
                            }));
                        } else if (data.status === 'error') {
                            console.error("Query error:", data.error);
                            showToast(data.error || 'Query failed', 'danger');
                            setState(prevState => ({ ...prevState, animation: false }));
                        } else {
                            console.log("Query still processing, continuing polling");
                            // Aggiungiamo un indicatore di progresso
                            setState(prevState => ({
                                ...prevState,
                                pollingAttempts: attempt + 1
                            }));
                            setTimeout(() => pollQueryStatus(requestId, attempt + 1), 1000);
                        }
                    } catch (error) {
                        console.error("Error parsing response:", error);
                        console.error("Response was:", text);
                        showToast('Error checking query status', 'danger');
                        setState(prevState => ({ ...prevState, animation: false }));
                    }
                })
                .catch(error => {
                    console.error("Network error during polling:", error);
                    showToast('Error checking query status', 'danger');
                    setState(prevState => ({ ...prevState, animation: false }));
                });
            };
    
            // Richiesta iniziale
            fetch('/api/info/', {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ 
                    Id: infoId, 
                    DB: selectedDb.id,
                    Param: state.param 
                })
            })
            .then(response => {
                console.log("Initial response status:", response.status);
                return response.json();
            })
            .then(data => {
                console.log("Initial response data:", data);
                if (data.request_id) {
                    console.log("Starting polling for request:", data.request_id);
                    pollQueryStatus(data.request_id, 0);
                } else if (Array.isArray(data)) {
                    // Risposta diretta (retrocompatibilità)
                    console.log("Direct response received:", data);
                    setState(prevState => ({
                        ...prevState,
                        result: data,
                        animation: false
                    }));
                }
            })
            .catch(error => {
                console.error("Query error:", error);
                showToast('Error executing query', 'danger');
                setState(prevState => ({ ...prevState, animation: false }));
            });
        }
    }, [selectedDb, state.param, showToast]);

    // Aggiorniamo la referenza di executeQuery
    useEffect(() => {
        executeQueryRef.current = executeQuery;
    }, [executeQuery]);

    // Funzione per gestire i cambiamenti nell'input di ricerca
    const handleInputChange = useCallback((e) => {
        const { value } = e.target;
        setState(prevState => ({ ...prevState, param: value }));
    }, []);

    // Funzione per gestire la ricerca
    const handleSearch = useCallback(() => {
        if (!selectedDb || selectedDb.id === -1) {
            showToast('Nessun database selezionato. Scegline uno usando il menu "Choose DB" sulla barra in alto.', 'info');
        } else if (state.info === -1) {
            showToast('Si prega di selezionare un informazione dal menu a discesa.', 'warning');
        } else if (state.param === '') {
            showToast('Si prega di inserire un parametro di ricerca.', 'warning');
        } else {
            executeQuery(state.info);
        }
    }, [selectedDb, state.info, state.param, showToast, executeQuery]);

    // Funzione per renderizzare gli elementi del menu a discesa delle informazioni
    const renderInfoDropdownItems = useCallback(() => {
        console.log("Rendering info dropdown items, infos:", state.infos);
        return state.infos.length > 0 ? (
            state.infos.map((info) => (
                <Dropdown.Item 
                    key={'dd' + info.Id}
                    onClick={() => handleInfoSelection(info)}
                >
                    {info.name}
                </Dropdown.Item>
            ))
        ) : (
            <Dropdown.Item disabled>No information available</Dropdown.Item>
        );
    }, [state.infos, handleInfoSelection]);

    // Funzione per renderizzare l'input di ricerca e il pulsante. Funziona anche con tasto invio della tastiera
    const renderSearchInputAndButton = useCallback(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                handleSearch();
            }
        };
    
        return (
            <div>
                <InputGroup>
                    <Form.Control
                        placeholder={state.filter}
                        value={state.param}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                    />
                    <Button 
                        variant='primary' 
                        disabled={state.animation} 
                        onClick={handleSearch}
                    >
                        {state.animation ? 
                            <Icon.GearFill 
                                className="spinner-gear" 
                                role="status" 
                                style={{ display: 'inline-block' }}
                            /> : 
                            <Icon.Search/>
                        }
                    </Button>
                </InputGroup>
                {state.animation && 
                    <div className="text-center mt-2 text-muted small">
                        <span>Processing request... </span>
                        {state.pollingAttempts > 0 && 
                            <span>({state.pollingAttempts} of {MAX_POLLING_ATTEMPTS} attempts)</span>
                        }
                    </div>
                }
            </div>
        );
    }, [state.filter, state.param, state.animation, state.pollingAttempts, handleSearch, handleInputChange]);

    // Funzione per renderizzare la tabella dei dati
    const renderDataTable = useCallback(() => {
        console.log("=== RENDER DATA TABLE ===");
        console.log("State info:", state.info);
        console.log("State result:", state.result);
        console.log("Is result array?", Array.isArray(state.result));
        if (state.info === -1 || state.result === 'Query failed') {
            console.log("No data to display - returning empty span");
            return <span></span>;
        }
        
        const keys = getKeys();
        console.log("Keys for table:", keys);
        
        return (
            <DTable 
                callBack={getDownloadData} 
                data={state.result} 
                keys={getKeys()} 
                filtered={state.filter} 
            />
        );
    }, [state.info, state.result, state.filter, getDownloadData, getKeys]);

    // Funzione per renderizzare il container dei messaggi toast
    const renderToastContainer = useCallback(() => {
        return (
            <ToastContainer position='bottom-end'>
                <Toast 
                    className='m-3' 
                    bg={state.toastbg} 
                    onClose={() => setShow(false)} 
                    show={state.showToast} 
                    delay={10000} 
                    autohide
                >
                    <Toast.Header>
                        <strong className="me-auto">Warning</strong>
                    </Toast.Header>
                    <Toast.Body>{state.toastContent}</Toast.Body>
                </Toast>
            </ToastContainer>
        );
    }, [state.toastbg, state.showToast, state.toastContent, setShow]);

    // Nuova funzione per renderizzare le informazioni del database
    const renderDbInfo = useCallback(() => {
        if (!selectedDb || selectedDb.id === -1) {
            return (
                <Alert variant="info" className="mb-2 py-2 small">
                    <span style={{ fontSize: '1rem' }}>Please select a database using the "Choose DB" option in the navigation bar.</span>
                </Alert>
            );
        }

        return (
            <Alert variant="success" className="mb-2 py-2 small">
                <span style={{ fontSize: '1rem' }}><strong>{selectedDb.name}</strong> - DB ready to query</span>
            </Alert>
        );
    }, [selectedDb]);

    // Rendering del componente
    console.log("DbNavigator render - state:", state);
    console.log("DbNavigator - context:", { selectedDb });

    return (
        <div>
            <Container fluid>
                {/* Renderizza le informazioni del database */}
                {renderDbInfo()}

                <Row className='mb-3 d-flex align-items-center'>
                    {/* Colonna per il menu a discesa delle informazioni */}
                    <Col className='col-4 d-flex justify-content-start'>    
                        <Dropdown>
                            <Dropdown.Toggle 
                                disabled={state.animation} 
                                variant="primary"
                            >
                                {state.selectedInfo}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {renderInfoDropdownItems()}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>

                    {/* Colonna per l'input e il pulsante di ricerca */}
                    <Col>
                        {renderSearchInputAndButton()}
                    </Col>

                    {/* Colonna per il pulsante di esportazione */}
                    <Col className='col-4 d-flex justify-content-end'>
                        <Export 
                            filename={`${state.selectedInfo || 'Query'}_${state.param || 'All'}`}
                            data={state.result}
                        />
                    </Col>
                </Row>

                {/* Riga per visualizzazioen del tooltip */}
                {state.selectedInfoTooltip && (
                    <Row className="mb-3">
                        <Col>
                            <Alert variant="info" className="py-2 small">
                                <strong>Info tips:</strong> {state.selectedInfoTooltip}
                            </Alert>
                        </Col>
                    </Row>
                )}

                {/* Rendering condizionale della tabella dei dati */}
                {renderDataTable()}
            </Container>

            {/* Container per i messaggi toast */}
            {renderToastContainer()}
        </div>
    );
}

export default DbNavigator;