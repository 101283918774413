import React, { Component } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";

export class LicenseGen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            duration: 0,
            owner: '',
            emailSent: false,
            error: null,
            isSubmitting: false
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ isSubmitting: true, error: null, emailSent: false });

        const newLicense = {
            owner: this.state.owner,
            duration: this.state.duration,
            active: false,
            in_use: false
        };

        this.props.addLicense(newLicense)
            .then(() => {
                this.setState({ 
                    emailSent: true, 
                    error: null,
                    duration: 0, 
                    owner: '',
                    isSubmitting: false
                });
            })
            .catch(error => {
                console.error("Error creating license:", error);
                this.setState({ 
                    error: "An error occurred while creating the license. Please try again.",
                    emailSent: false,
                    isSubmitting: false
                });
            });
    }

    handleClose = () => {
        this.setState({ 
            emailSent: false, 
            error: null,
            duration: 0,
            owner: '',
            isSubmitting: false
        });
        this.props.callback(false);
    }

    render() {
        return (
            <Modal 
                show={this.props.show} 
                onHide={this.handleClose}
                scrollable
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Key Generation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.emailSent && (
                        <Alert variant="success">
                            The license has been created successfully. An email with the activation key has been sent to the provided address.
                        </Alert>
                    )}
                    {this.state.error && (
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                    )}
                    <Form className='ms-5 me-5 mt-3 mb-3'>
                        <Form.Group className="mb-3">
                            <Form.Label>Send To:</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="name@example.com" 
                                value={this.state.owner}
                                onChange={(e) => this.setState({owner: e.target.value})} 
                                disabled={this.state.isSubmitting}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Duration</Form.Label>
                            <div>
                                {[
                                    { label: "3 Months", value: 3 },
                                    { label: "6 Months", value: 6 },
                                    { label: "12 Months", value: 12 },
                                    { label: "Trial", value: 1 },
                                    { label: "Lifetime", value: -1 }
                                ].map(option => (
                                    <Form.Check 
                                        key={option.label}
                                        type="radio" 
                                        name='duration' 
                                        label={option.label} 
                                        onChange={() => this.setState({duration: option.value})}
                                        checked={this.state.duration === option.value}
                                        disabled={this.state.isSubmitting}
                                    />
                                ))}
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose} disabled={this.state.isSubmitting}>
                        Cancel
                    </Button>
                    <Button 
                        variant="primary" 
                        onClick={this.handleSubmit}
                        disabled={!this.state.owner || this.state.duration === 0 || this.state.isSubmitting}
                    >
                        {this.state.isSubmitting ? 'Generating...' : 'Confirm'}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}