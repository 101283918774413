
import { Component } from "react";
import { Modal,ListGroup,Row,Col, Badge,Alert } from "react-bootstrap";
import { CHANGED,NEW,DELETED, STATUS_CHANGED, STATUS_NEW, STATUS_DELETED } from './colors';

export class RowCompare extends Component{

    constructor(props){
        super(props)
        this.getKeys.bind(this)

        // Controlla cosa viene passato in props
        console.log("tabid from props:", this.props.tabid);
        console.log("selectedDBName from props:", this.props.selectedDBName);

        const pagesComp = JSON.parse(localStorage.getItem('pagesComp')) || {};
        const tabData = pagesComp[props.tabid] || {};
        console.log("pagesComp from localStorage:", pagesComp);
        console.log("tabData for current tab:", tabData);

        this.compareWith = tabData.compareWithName || 'Unknown';
        this.selectedDB = props.selectedDBName || 'Unknown';
    }

    getKeys = () => {
        if (this.props.keys !== undefined) {
            var k = this.props.keys.map(k => k.replace('_update', '')); // Modifica corretta della stringa
            k = this.props.pk.concat(k);  // Unisci con le chiavi primarie esistenti
            return k;
        }
        return ['none'];
    }

    render(){
        console.log("RowCompare state:", this.props.state);
        console.log("Props data passed to RowCompare:", this.props.data);
        console.log("Props keys:", this.props.keys);
        console.log("Props pk:", this.props.pk);
        return(
        <Modal className = 'modal' show = {this.props.show} onHide = {()=> this.props.callBack(false)}
        size="lg"
        scrollable
        aria-labelledby="contained-modal-title-vcenter"
        centered>
            <Modal.Header closeButton>
                <Modal.Title>Details</Modal.Title>
                {
                    this.props.state === 'New' ? 
                    <Badge className = 'ms-3' bg = 'success'>
                    New
                    </Badge>
                    :
                    this.props.state === 'Deleted' ? 
                    <Badge className = 'ms-3' bg = 'danger'>
                    Deleted
                    </Badge>
                    :
                    this.props.state === 'Changed' ? 
                    <Badge className = 'ms-3' bg = 'warning'>
                        Changed
                    </Badge>
                    : <p></p>
                }
            </Modal.Header>
            <Modal.Body>
                <Detail
                keys = {this.props.keys}
                compareWith = {this.compareWith}
                selectedDB = {this.selectedDB}
                pk = {this.props.pk}
                data = {this.props.data}
                state = {this.props.state}/>
            </Modal.Body>
        </Modal>
        )}
}

const Detail = (props) => {
    // Verifica che props.keys, props.data e props.pk siano definiti
    if (!props.keys || !props.data || !props.pk) {
        return <Alert variant="warning">No data available</Alert>;
    }

    // Mappatura delle chiavi e gestione dei campi primari
    var fields = props.keys.map(key => key.replace('_update', ''));
    var pk = props.pk.filter(e => !fields.includes(e));
    var allKeys = pk.concat(fields);
    var newKeys = pk.concat(props.keys);

    // Render per lo stato 'NEW'
    if (props.state === 'New') {
        return (
            <Row>
                <Col className="col-12">
                    <ListGroup variant="flush">
                        <ListGroup.Item variant="secondary">
                            {props.compareWith !== 'Unknown' ? props.compareWith : 'Dati mancanti'}
                        </ListGroup.Item>
                        {newKeys.map((k, index) => (
                            <ListGroup.Item key={index}>
                                <div>
                                    <p style={{ fontWeight: 700 }}>
                                        {k.replace('_update', '').replaceAll('_', ' ')}
                                    </p>
                                    <p>
                                        {props.data[k] === undefined || props.data[k] === null || props.data[k] === ' ' 
                                        ? '-' 
                                        : props.data[k].toString().replaceAll('||', ' || ')}
                                    </p>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
            </Row>
        );
    }

    // Render per lo stato 'DELETED'
    else if (props.state === 'Deleted') {
        return (
            <Row>
                <Col className="col-12">
                    <ListGroup variant="flush">
                        <ListGroup.Item variant="secondary">
                            {props.selectedDB !== 'Unknown' ? props.selectedDB : 'Dati mancanti'} (Old Values)
                        </ListGroup.Item>
                        {allKeys.map((k, index) => (
                            <ListGroup.Item key={index}>
                                <div>
                                    <p style={{ fontWeight: 700 }}>
                                        {k.replaceAll('_', ' ')}
                                    </p>
                                    <p>
                                        {props.data[k] === undefined || props.data[k] === null || props.data[k] === ' ' 
                                        ? '-' 
                                        : props.data[k].toString().replaceAll('||', ' || ')}
                                    </p>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
            </Row>
        );
    }

    // Render per lo stato 'CHANGED'
    else if (props.state === 'Changed') {
        const oldValues = props.data.old_values || {};
        const newValues = props.data || {};

        // Combina tutte le chiavi da old_values e new_values
        const allKeys = Array.from(new Set([...Object.keys(oldValues), ...Object.keys(newValues)]));

        return (
            <Row>
                <Col className="col-6">
                    <ListGroup variant="flush">
                        <ListGroup.Item variant="secondary">
                            {props.selectedDB} (Old Values)
                        </ListGroup.Item>
                        {allKeys.map((key, index) => {
                            const oldValue = oldValues[key] !== undefined ? oldValues[key] : '-'; 

                            return (
                                <ListGroup.Item key={index} style={{ minHeight: '50px' }}>
                                    <div>
                                        <p style={{ fontWeight: 700 }}>
                                            {key.replaceAll('_', ' ') || '-'}
                                        </p>
                                        <p>
                                            {oldValue === undefined || oldValue === null
                                                ? '-' 
                                                : oldValue.toString().replaceAll('||', ' || ')}
                                        </p>
                                    </div>
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                </Col>
                <Col className="col-6">
                    <ListGroup variant="flush">
                        <ListGroup.Item variant="secondary">
                            {props.compareWith} (New Values)
                        </ListGroup.Item>
                        {allKeys.map((key, index) => {
                            const oldValue = oldValues[key] !== undefined ? oldValues[key] : '-'; 
                            const newValue = newValues[key] !== undefined ? newValues[key] : '-'; 
                            const isChanged = oldValue !== newValue; 

                            return (
                                <ListGroup.Item key={index} style={{ minHeight: '50px' }}>
                                    <div>
                                        <p style={{ fontWeight: 700 }}>
                                            {key.replaceAll('_', ' ') || '-'}
                                        </p>
                                        <p style={{
                                            backgroundColor: isChanged ? 'rgba(255, 255, 153, 0.5)' : 'transparent',
                                            fontWeight: isChanged ? 'bold' : 'normal',
                                            padding: isChanged ? '2px' : '0'
                                        }}>
                                            {newValue === undefined || newValue === null
                                                ? '-' 
                                                : newValue.toString().replaceAll('||', ' || ')}
                                        </p>
                                    </div>
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                </Col>
            </Row>
        );
    }


    // Render di default (nel caso nessuno degli stati corrisponda)
    return (
        <ListGroup variant="flush">
            {allKeys.map((k, index) => (
                <ListGroup.Item key={index}>
                    <div>
                        <p style={{ fontWeight: 700 }}>
                            {k.replaceAll('_', ' ')}
                        </p>
                        <p>
                            {props.data[k] === undefined || props.data[k] === null 
                            ? '-' 
                            : props.data[k].toString().replaceAll('||', ' || ')}
                        </p>
                    </div>
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
};