import React from "react";
import { Button } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import * as XLSX from 'xlsx';

export function Export({ data, testata, filename = 'export' }) {
    const handleExport = () => {
        if (!data || data.length === 0) {
            alert('Nessun dato da scaricare');
            return;
        }

        try {
            // Crea un nuovo workbook
            const wb = XLSX.utils.book_new();

            // Crea un foglio per la testata
            if (testata && testata.length > 0) {
                const wsTestata = XLSX.utils.json_to_sheet(testata);
                XLSX.utils.book_append_sheet(wb, wsTestata, "Testata");
            }

            // Crea un foglio per i dati
            const wsData = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(wb, wsData, "Dati");

            // Scarica il file Excel
            XLSX.writeFile(wb, `${filename}.xlsx`);

            alert(`${filename}.xlsx scaricato`);
        } catch (error) {
            console.error("Errore durante l'esportazione in Excel:", error);
            alert("Errore durante l'esportazione in Excel. Per favore riprova.");
        }
    };

    return (
        <Button 
            variant='outline-success' 
            onClick={handleExport}
        >
            Export Excel <Icon.BoxArrowDown size={20} className='ms-1' />
        </Button>
    );
}