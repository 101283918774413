import React, { useState,useEffect } from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {useNavigate,Link} from 'react-router-dom'
import { Container,Card,Form,Button } from 'react-bootstrap'
import * as actions from '../store/actions/auth'


export default function Login (){
    const [userName, setUserName] = useState(null)
    const [userPwd, setUserPwd] = useState(null)
    const error = useSelector((state)=> state.error)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cardBackgroundColor = '#d9e0ea';  // Grigio chiaro
    const activeOuterBorderColor = '#18303e';  // Blu scuro
    const activeInnerBorderColor = '#c95e15';  // Arancione

    const login = (e)=>{
        e.preventDefault()
        if (userName !== null & userPwd !== null){
            dispatch(actions.authLogin(userName, userPwd,navigate))
            
        }
        else{ 
            alert('Mandatory field empty')
            return null}
    }

    useEffect(() => {
        // Aggiungo la classe 'login-page' al body quando il componente viene montato
        document.body.classList.add('login-page');
    
        // Rimuovo la classe quando il componente viene smontato
        return () => {
            document.body.classList.remove('login-page');
        };
    }, []);

    return(
        <Container className='w-50 mt-5'> 
            <Card className=''
                style={{
                    border: `2.5px solid ${activeOuterBorderColor}`,
                    boxShadow: `inset 0 0 0 1px ${activeInnerBorderColor}`,
                    backgroundColor: cardBackgroundColor,
                }}>
            <Card.Header> <h2 className = 'mb-3'>
            PumaTools Login
            </h2></Card.Header>
        <Card.Body>
            <Form className = '' onSubmit = {(e)=> login(e)} >
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label style = {{fontWeight : 'bold' }}>UserName</Form.Label>
                <Form.Control 
                type="text" 
                placeholder="Enter username" 
                onChange = {(e)=> setUserName(e.target.value)}
                style={{ backgroundColor: '#fbfcfe' }} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label style = {{fontWeight : 'bold' }}>Password</Form.Label>
                <Form.Control 
                type="password" 
                placeholder="Password"
                onChange = {(e)=> setUserPwd(e.target.value)}
                style={{ backgroundColor: '#fbfcfe' }}/>
            </Form.Group>
            {error ? <p className= 'text-danger'>
                {JSON.stringify(error)}
            </p> : null}
            <Button 
                variant="primary" 
                type="submit" 
                className="mb-3"
                style={{ backgroundColor: '#15253f', borderColor: '#15253f' }}
            >
                Submit
            </Button>
            </Form>
            <Link to="/password-reset">Forgot your password?</Link>
        </Card.Body>
        <Card.Footer>
            <Button variant="link"  type="submit" onClick = {()=>navigate('/signup')}>
                Register and activate now your account
            </Button>
            </Card.Footer>
        </Card>

        {/* Messaggio per la licenza di prova */}
        <div className="mt-4 text-center">
            <p>Do you want to try PumaTools? Ask for a free trial license at: <a href="mailto:pumatools@ares-bi.com">pumatools@ares-bi.com</a></p>
        </div>

            {/* Titolo e Video YouTube
            <div className="mt-5">
                <h3 className="mb-3">Guida all'uso di PumaTool</h3>
                <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/XkDSQq0fwfU?si=e23ifkU0zNtu_9fw"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                ></iframe>
            </div>
            */}
        </Container>
    )

}

/*
const mapStateToProps = (state) =>{
    return {
        error: state.error,
        user : state.user
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        onAuth : (username, password) => {
            dispatch(actions.authLogin(username, password))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)*/