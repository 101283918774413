import React, { Component } from 'react';
import { Container, Dropdown, Row, Col, Alert, Button, Spinner } from 'react-bootstrap';
import { variables } from '../Variables';
import { DTableComp } from './DTableComp';
import { DbContext } from '../contexts/DbContext';
import * as XLSX from 'xlsx';
import { Export } from './Export';
import PropTypes from 'prop-types';


  // Stile dei bottoni
const buttonStyle = { width: '160px', maxWidth: '100%' };

export class CompareDB extends Component {
    static contextType = DbContext;

    constructor(props) {
        super(props);
        this.state = {
            result: [],  // Risultati del confronto
            infos: [],   // Array che contiene le opzioni per il menu a discesa
            info: this.getInitialInfo(props),  // Informazione selezionata
            selectedInfo: 'Select query',  // Testo visualizzato nel dropdown
            filter: this.getInitialFilter(props),  // Filtro applicato
            animation: false,  // Flag per l'animazione di caricamento
            error: null,  // Messaggio di errore
            compareWith: this.getInitialCompareWith(props),  // Database con cui confrontare
            isReadyToQuery: false,
            isQuerying: false,
        };
        this._isMounted = false;  // Flag per gestire gli aggiornamenti di stato asincroni
        console.log("Initial state:", this.state);
    }

    // Funzione per esportare i dati in Excel
    exportToExcel = () => {
        const { result } = this.state;
        const { infoName } = this.props;  // Aggiungi questa riga per ricevere la prop
    
        if (result && result.length > 0) {
            const fileName = `Confronto_${infoName || 'Query'}.xlsx`;  // Usa infoName
            
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(result);
            XLSX.utils.book_append_sheet(wb, ws, "Confronto");
            
            XLSX.writeFile(wb, fileName);
            console.log('File Excel generato e scaricato:', fileName);
        } else {
            console.error('Nessun dato disponibile per l\'esportazione');
            this.setState({ error: 'Nessun dato disponibile per l\'esportazione' });
        }
    };

    // Metodi per ottenere i valori iniziali dallo storage locale
    getInitialInfo(props) {
        const savedState = JSON.parse(localStorage.getItem('pagesComp'));
        return savedState && savedState[props.id] ? savedState[props.id].info : -1;
    }

    getInitialFilter(props) {
        const savedState = JSON.parse(localStorage.getItem('pagesComp'));
        return savedState && savedState[props.id] ? savedState[props.id].filter : '';
    }

    getInitialCompareWith(props) {
        const savedState = JSON.parse(localStorage.getItem('pagesComp'));
        return {
            name: savedState && savedState[props.id] ? savedState[props.id].compareWithName : 'None',
            id: savedState && savedState[props.id] ? savedState[props.id].compareWithId : -1,
            result: []
        };
    }

    // Metodo per aggiornare la lista delle informazioni e eseguire confronti
    refreshList = (all = true) => {
        console.log("API_URL:", variables.API_URL);
        console.log("refreshList called with all:", all);
        console.log("Current state before refresh:", this.state);

        if (all) {
            // Fetch delle informazioni disponibili
            // L'endpoint comparedb si presenta al server dando l'informazione del suo nome
            const endpoint = '/api/info?component=comparedb';
            console.log("Fetching from:", endpoint);

            fetch(endpoint, { 
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}`
                }
            })
            .then(response => {
                console.log("API response status:", response.status);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.log("Parsed API response:", data);
                if (this._isMounted) {
                    this.setState({ infos: data, error: null }, () => {
                        console.log("Updated state.infos:", this.state.infos);
                    });
                }
            })
            .catch(error => {
                console.error("Error fetching infos:", error);
                if (this._isMounted) {
                    this.setState({ infos: [], error: error.message });
                }
            });
        }

        // Esecuzione del confronto se tutti i parametri necessari sono presenti
        if (this.state.info !== -1 && this.state.db !== -1 && this.state.compareWith.id !== -1) {
            this.setState({ animation: true, error: null });

            const requestBody = {
                info: this.state.info,
                iddb1: this.state.db,
                iddb2: this.state.compareWith.id
            };

            console.log("Sending comparedb request with body:", requestBody);

            fetch('/api/diffs/', {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(requestBody)
            })
            .then(response => {
                console.log("CompareDB API response status:", response.status);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.log("CompareDB result:", data);
                if (this._isMounted) {
                    if (data === "Query failed" || (typeof data === 'object' && data.error)) {
                        throw new Error(data.error || "Query failed");
                    }
                    this.setState({ 
                        result: Array.isArray(data) ? data : [], 
                        animation: false, 
                        error: null 
                    }, () => {
                        console.log("Updated state after comparison:", this.state);
                        this.saveStateToLocalStorage();
                    });
                }
            })
            .catch(error => {
                console.error("Error in comparison:", error);
                if (this._isMounted) {
                    this.setState({ animation: false, result: [], error: error.message });
                }
            });
        }
    }

    // Salva lo stato corrente nel localStorage
    saveStateToLocalStorage = () => {
        const dataToSave = {
            info: this.state.info,
            compareWithId: this.state.compareWith ? this.state.compareWith.id : null,
            compareWithName: this.state.compareWith ? this.state.compareWith.name : null,
            filter: this.state.filter
        };
        let pages = JSON.parse(localStorage.getItem('pagesComp')) || {};
        pages[this.props.id] = dataToSave;
        localStorage.setItem('pagesComp', JSON.stringify(pages));
    }

    // Metodo del ciclo di vita: componente montato
    componentDidMount() {
        this._isMounted = true;
        console.log("componentDidMount - current state:", this.state);
        console.log("componentDidMount - current context:", this.context);
        
        const { selectedDb } = this.context;
        if (selectedDb && selectedDb.id !== -1) {
            this.setState({
                db: selectedDb.id,
                dbName: selectedDb.name
            }, () => this.refreshList());
        } else {
            this.refreshList();
        }
    }

    // Metodo del ciclo di vita: componente smontato
    componentWillUnmount() {
        this._isMounted = false;
    }

    // Metodo del ciclo di vita: componente aggiornato
    componentDidUpdate(prevProps, prevState) {
        const { selectedDb } = this.context;
        
        if (selectedDb && selectedDb.id !== prevState.db) {
            console.log("Selected database changed in CompareDB component");
            this.setState({
                db: selectedDb.id,
                dbName: selectedDb.name
            }, () => this.refreshList());
        }
    }

    // Metodo per ottenere le chiavi del risultato
    getKeys = () => {
        return this.state.result.length > 0 ? Object.keys(this.state.result[0]) : [];
    }

    // Gestione della selezione delle informazioni
    handleInfoSelection = (info) => {
        console.log("Info selected:", info);
        const { selectedDb } = this.context;
        if (!selectedDb || selectedDb.id === -1) {
            alert('No database selected');
        } else {
            this.setState({
                info: info.Id,
                selectedInfo: info.name,
                filter: info.query
            }, () => {
                console.log("State after info selection:", this.state);
                if (this.props.changeName) {
                    this.props.changeName(this.state.selectedInfo, this.props.id);
                }
                this.checkIfReadyToQuery();
            });
        }
    }

    // Gestione della selezione del database di confronto
    handleCompareWithSelection = (db) => {
        console.log("Compare with selected:", db);
        this.setState({
            compareWith: {
                name: db.name,
                id: db.id
            }
        }, () => {
            console.log("State after compare with selection:", {
                compareWithId: this.state.compareWith.id,
                compareWithName: this.state.compareWith.name
            });
            this.checkIfReadyToQuery();
        });
    }

    // Verifica se è possibile avviare la query
    checkIfReadyToQuery = () => {
        const { selectedDb } = this.context;
        const { info, compareWith } = this.state;
        const isReady = info !== -1 && selectedDb && selectedDb.id !== -1 && compareWith && compareWith.id !== -1;
        this.setState({ isReadyToQuery: isReady });
    }

    // Avvio della query di confronto
    startQuery = (page = 1, pageSize = 1000) => {
        console.log('State at startQuery:', this.state);
        console.log('Context at startQuery:', this.context);

        const { selectedDb } = this.context;

        if (!selectedDb || !selectedDb.id) {
            console.error('selectedDb is undefined or does not have an id');
            return;
        }

        if (!this.state.compareWith.id) {
            console.error('compareWith is undefined or does not have an id');
            return;
        }

        const requestData = {
            info: this.state.info,
            iddb1: selectedDb.id,
            iddb2: this.state.compareWith.id,
            page: page,
            page_size: pageSize
        };

        console.log('Request data:', requestData);
        console.log('Database IDs being compared:', {
            iddb1: selectedDb.id,
            iddb2: this.state.compareWith.id
        });

        this.setState({ isQuerying: true });

        fetch('/api/diffs/', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(requestData)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Response data:', data);
            this.setState({
                result: data.data,
                totalRecords: data.total_records,
                currentPage: data.page,
                pageSize: data.page_size,
                totalPages: data.total_pages,
                isQuerying: false
            });
        })
        .catch(error => {
            console.error('Error:', error);
            this.setState({ error: error.message, isQuerying: false });
        });
    };

    // Nuovo metodo per renderizzare le informazioni del database
    renderDbInfo = () => {
        const { selectedDb } = this.context;
        if (!selectedDb || selectedDb.id === -1) {
            return (
                <Alert variant="info" className="mb-2 py-2 small">
                    <span style={{ fontSize: '1rem' }}>Please select a database using the "Choose DB" option in the navigation bar.</span>
                </Alert>
            );
        }

        return (
            <Alert variant="success" className="mb-2 py-2 small">
                <span style={{ fontSize: '1rem' }}><strong>{selectedDb.name}</strong> - DB ready for comparing DB</span>
            </Alert>
        );
    }

    render() {
        console.log("Rendering CompareDB component");
        console.log("Current state:", this.state);
        console.log("Current context:", this.context);
        const { dbs, selectedDb } = this.context;

        return (
            <Container fluid>
                {this.renderDbInfo()}

                <Row className='mb-3'>
                    <Col md={4} className='d-flex align-items-center'>
                        <Dropdown className="ms-0">
                            <Dropdown.Toggle 
                                disabled={this.state.isQuerying}
                                id="dropdown-button-dark-example1" 
                                variant="primary">
                                {this.state.isQuerying ? 'Loading... ' : this.state.selectedInfo}
                                {this.state.isQuerying && 
                                    <span 
                                        className="spinner-border spinner-border-sm" 
                                        role="status" 
                                        style={{marginLeft: '5px'}}
                                    />
                                }
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {this.state.infos && this.state.infos.length > 0 ? (
                                    this.state.infos.map((info) => (
                                        <Dropdown.Item 
                                            key={'ddComp' + info.Id}
                                            onClick={() => this.handleInfoSelection(info)}
                                        >
                                            {info.name}
                                        </Dropdown.Item>
                                    ))
                                ) : (
                                    <Dropdown.Item disabled>No information available</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col md={4} className='d-flex align-items-center'>
                        <div className='d-flex align-items-center flex-nowrap me-2'>
                            <span className='text-dark me-2 text-nowrap'>Compare with</span>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-button-dark-example1" variant="outline-secondary">
                                    {this.state.compareWith.name}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {dbs && dbs.length > 0 ? (
                                        dbs.map((d) => (
                                            <Dropdown.Item 
                                                key={'compDB' + d.id}
                                                onClick={() => this.handleCompareWithSelection(d)}
                                            >
                                                {d.name}
                                            </Dropdown.Item>
                                        ))
                                    ) : (
                                        <Dropdown.Item disabled>No databases available</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col md={4} className='d-flex flex-column justify-content-center align-items-end pt-5'>
                        <Button 
                            variant="success" 
                            onClick={() => this.startQuery(1)}
                            disabled={!this.state.isReadyToQuery || this.state.isQuerying}
                            className="mb-2"
                            style={{ ...buttonStyle, width: '145px' }}  // Imposta la larghezza desiderata
                        >
                            Start Query
                        </Button>
                        <Export 
                            filename={`Confronto_${this.state.selectedInfo}`}  // Usa il nome della query selezionata
                            data={this.state.result || []}
                            name={`Confronto ${this.state.selectedInfo}`}      // Aggiorna anche il nome visualizzato
                            exportFunction={this.exportToExcel}
                            disabled={!this.state.result || this.state.result.length === 0}
                        />
                    </Col>
                </Row>
                <Row>
                    {this.state.error && (
                        <Alert variant="danger">
                            Error: {this.state.error}
                        </Alert>
                    )}
                    {this.state.info === -1 ? (
                        <Container className='d-flex justify-content-center'>
                            <Alert className='col-4' variant='danger'>
                                Select an information to start
                            </Alert>
                        </Container>
                    ) : this.state.result.length > 0 ? (
                        <>
                            <p>Number of results: {this.state.result.length}</p>
                            <DTableComp 
                                size='sm' 
                                data={this.state.result} 
                                keys={this.getKeys()} 
                                filtered={this.state.filter} 
                                tabid={this.props.id}
                                callback={() => {}} 
                                infoName={this.state.selectedInfo}
                            />
                        </>
                    ) : (
                        <p>No results to display</p>
                    )}
                </Row>
            </Container>
        );
    }
}

CompareDB.propTypes = {
    id: PropTypes.number,
    changeName: PropTypes.func,
    infoName: PropTypes.string
};

CompareDB.defaultProps = {
    infoName: 'Query'
};

export default CompareDB;