import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';

// Importa le icone personalizzate
import { ReactComponent as DbNavigatorIcon } from '../assets/img/db-navigator-icon.svg';
import { ReactComponent as CompareDBIcon } from '../assets/img/comparedb-icon.svg';
import { ReactComponent as CompareSurveyIcon } from '../assets/img/compare-survey-icon.svg';
import { ReactComponent as FinriskIcon } from '../assets/img/finrisk-icon.svg';
import { ReactComponent as FinBilIcon } from '../assets/img/finbil-icon.svg';
import { ReactComponent as ImportIcon } from '../assets/img/import-icon.svg';
import { ReactComponent as UsersIcon } from '../assets/img/users-icon.svg';
import { ReactComponent as LicensesIcon } from '../assets/img/licenses-icon.svg';

const navigationItems = [
  { name: 'DB Navigator', path: '/db-navigator', icon: DbNavigatorIcon, superuserOnly: false },
  { name: 'CompareDB', path: '/comparedb', icon: CompareDBIcon, superuserOnly: false },
  { name: 'Compare Survey', path: '/compare-survey', icon: CompareSurveyIcon, superuserOnly: false },
  { name: 'FinRisk', path: '/finrisk', icon: FinriskIcon, superuserOnly: false, requiresFinRisk: true },
  { name: 'FinBil', path: '/finbil', icon: FinBilIcon, superuserOnly: false, requiresFinBil: true },
  { name: 'Import', path: '/import', icon: ImportIcon, superuserOnly: true },
  { name: 'Users', path: '/users', icon: UsersIcon, superuserOnly: true },
  { name: 'Licenses', path: '/licenses', icon: LicensesIcon, superuserOnly: true }
];

export function Home({ isSuperuser, isFinRisk, isFinBil, user  }) {
  console.log("Home component props:", { isSuperuser, isFinRisk, isFinBil });
  const cardBackgroundColor = '#d9e0ea';
  const disabledCardBackgroundColor = '#e9ecef';
  const disabledTextColor = '#6c757d';
  const activeOuterBorderColor = '#18303e';
  const activeInnerBorderColor = '#c95e15';
  const disabledBorderColor = '#a0a0a0';
  
  const isDisabled = (item) => {
    if (isSuperuser) return false; // Il superuser ha accesso a tutto
    if (item.superuserOnly) return true;
    if (item.requiresFinRisk && !isFinRisk) return true;
    if (item.requiresFinBil && !isFinBil) return true;
    return false;
  };

  return (
    <div style={{ backgroundColor: '#fbfcfe', minHeight: '100vh', paddingTop: '2px' }}>
      <Container className="mt-5">
        <h1 className="text-center mb-5" style={{ fontSize: '2.2rem' }}>Welcome{user ? `, ${user}` : ''}</h1>
        <Row xs={1} md={2} lg={4} className="g-4">
          {navigationItems.map((item, idx) => {
            const isItemDisabled = isDisabled(item);
            
            console.log(`Item ${item.name} - isDisabled:`, isItemDisabled);

            const outerBorderColor = isItemDisabled ? disabledBorderColor : activeOuterBorderColor;
            const innerBorderColor = isItemDisabled ? disabledBorderColor : activeInnerBorderColor;

            return (
              <Col key={idx}>
                <Card 
                  as={isItemDisabled ? 'div' : Link}
                  to={!isItemDisabled ? item.path : undefined}
                  className={`h-100 ${!isItemDisabled ? 'text-decoration-none' : ''}`}
                  style={{
                    border: `2.5px solid ${outerBorderColor}`,
                    boxShadow: `inset 0 0 0 1px ${innerBorderColor}`,
                    backgroundColor: isItemDisabled ? disabledCardBackgroundColor : cardBackgroundColor,
                    transition: 'transform 0.3s ease-in-out',
                    cursor: isItemDisabled ? 'not-allowed' : 'pointer',
                  }}
                  onMouseEnter={(e) => !isItemDisabled && (e.currentTarget.style.transform = 'scale(1.05)')}
                  onMouseLeave={(e) => !isItemDisabled && (e.currentTarget.style.transform = 'scale(1)')}
                >
                  <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                    <item.icon 
                      width={50} 
                      height={50} 
                      className="mb-3" 
                      style={{ opacity: isItemDisabled ? 0.5 : 1 }}
                    />
                    <Card.Title style={{ color: isItemDisabled ? disabledTextColor : 'inherit', fontSize: '1rem' }}>
                      {item.name}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default Home;