import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import reducer from './store/reducers/auth';
import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux';
import { DbProvider } from './contexts/DbContext';

console.log('API URL:', process.env.REACT_APP_API_URL);
console.log('process.env at start of index.js:', process.env);

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhances(applyMiddleware(thunk)));

const app = (
  <Provider store={store}>
    <DbProvider>
      <App />
    </DbProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

reportWebVitals();