import React, { useState, useEffect, useRef } from 'react';
import { Container, Dropdown, Button, Table, Alert, Row, Col, Spinner, ProgressBar as BootstrapProgressBar } from 'react-bootstrap';
import axios from 'axios';
import { DTableSurveyComp } from './DTableSurveyComp';
import * as XLSX from 'xlsx';
import { Export } from './Export';
import './CompareSurvey.scss';  // Importa il file SCSS

const CompareSurvey = () => {
  // Stati principali per gestire i dati del form e il progresso
  const [surveyTypes, setSurveyTypes] = useState([]);
  const [selectedSurveyType, setSelectedSurveyType] = useState(null);
  const [survey1, setSurvey1] = useState(null);
  const [survey2, setSurvey2] = useState(null);
  const [comparisonResult, setComparisonResult] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [dataReceived, setDataReceived] = useState(false);
  const [testata, setTestata] = useState(null);
  const [surveyId, setSurveyId] = useState(null);
  const pollIntervalRef = useRef(null);
  const [isXbrl, setIsXbrl] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  // Funzione per l'esportazione dei dati in Excel
  const exportToExcel = () => {
    if (comparisonResult && comparisonResult.length > 0) {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(comparisonResult);
      XLSX.utils.book_append_sheet(wb, ws, "Confronto");
      XLSX.writeFile(wb, "Confronto_base.xlsx");
      console.log('File Excel generato e scaricato');
    } else {
      setError('Nessun dato disponibile per l\'esportazione');
    }
  };

  // Stile dei bottoni
  const buttonStyle = { width: '160px', maxWidth: '100%' };
  const surveyTypeButtonStyle = { ...buttonStyle, width: '200px' };

  // Funzione per gestire il caricamento dei tipi di survey dal server
  useEffect(() => {
    axios.get('/api/survey-types/')
      .then(response => {
        setSurveyTypes(response.data);
      })
      .catch(error => {
        console.error('Errore nel recupero dei tipi di survey:', error);
        setError('Failed to fetch survey types');
      });
  }, []);

// Effetto per gestire il polling del progresso
useEffect(() => {
  console.log('surveyId changed:', surveyId);
  return () => {
    if (pollIntervalRef.current) {
      console.log('Cleaning up progress polling');
      clearInterval(pollIntervalRef.current);
    }
  };
}, [surveyId]);

// Effetto per il logging del progresso
  useEffect(() => {
    console.log('Progress updated:', progress);
  }, [progress]);

  useEffect(() => {
    console.log('surveyId in state:', surveyId);
    if (surveyId) {
      console.log(`Starting progress polling for survey ID: ${surveyId}`);
      pollProgress(surveyId);
    }
  }, [surveyId]);

  useEffect(() => {
    if (error) {
      console.error('Error state updated:', error);
    }
  }, [error]);
  
  useEffect(() => {
    console.log('isLoading changed:', isLoading);
  }, [isLoading]);

// Monitora i cambiamenti di comparisonresult
  useEffect(() => {
    console.log('comparisonResult changed:', comparisonResult ? `Data received (${comparisonResult.length} items)` : 'No data');
  }, [comparisonResult]);

// Effetto per verificare se lo stato isvisible cambia
  useEffect(() => {
    console.log('isVisible changed:', isVisible);
    console.log('Current classes:', `fade-content ${isVisible ? 'fade-enter fade-enter-active' : 'fade-exit fade-exit-active'}`);
  }, [isVisible]);

// Effetto per loggare quando le classi cambiano
  useEffect(() => {
    console.log('Classe applicata:', `fade-content ${isVisible ? 'fade-enter fade-enter-active' : 'fade-exit fade-exit-active'}`);
    const element = document.querySelector('.fade-content');
    if (element) {
      console.log('Stili computati:', window.getComputedStyle(element));
    }
  }, [isVisible]);

  // Funzione per la selezione del tipo di survey
  const handleSurveyTypeSelect = (surveyType) => {
    setSelectedSurveyType(surveyType);
    setIsXbrl(surveyType.survey_type.toLowerCase() === 'xbrl');
  };
  
// Log per vedere quando il componente si monta
  useEffect(() => {
    console.log('CompareSurvey component mounted');
  }, []);

  // Funzione per il caricamento dei file
  const handleFileUpload = (event, surveyNumber) => {
    const file = event.target.files[0];
    if (file) {
      console.log(`File ${surveyNumber} selected:`, file.name);
      if (!selectedSurveyType) {
        setError('Please select a survey type before uploading a file');
        return;
      }

      const fileExtension = file.name.split('.').pop().toLowerCase();
      const allowedExtensions = ['txt', 'xml', 'xbrl'];

      if (!allowedExtensions.includes(fileExtension)) {
        setError(`Invalid file type. Allowed extensions are: ${allowedExtensions.join(', ')}`);
        return;
      }

      if (fileExtension !== selectedSurveyType.survey_type.toLowerCase()) {
        setError(`Invalid file type. Expected .${selectedSurveyType.survey_type.toLowerCase()} for the selected survey type`);
        return;
      }

      if (surveyNumber === 1) {
        setSurvey1(file);
      } else {
        setSurvey2(file);
      }
    }
  };

  // Funzione per eseguire il polling e aggiornare il progresso
  const pollProgress = (id) => {
    console.log('Starting progress polling for survey ID:', id);
    const interval = setInterval(async () => {
      try {
        console.log('Sending progress request for survey ID:', id);
        const response = await axios.get(`/api/progress/${id}/`);
        console.log('Progress response:', response.data);
  
        const progressValue = response.data.progress || response.data;
  
        if (progressValue !== undefined) {
          console.log('Setting progress to:', progressValue);
          setProgress(prev => Math.max(prev, progressValue));
  
          if (progressValue >= 100) {
            console.log('Progress complete');
            clearInterval(interval);
            setIsLoading(false);
          }
        } else {
          console.warn('Progress is undefined in response');
        }
      } catch (error) {
        console.error('Error in progress polling:', error);
        clearInterval(interval);
        setIsLoading(false);
      }
    }, 3000); //intervallo di polling di tre secondi
  
    pollIntervalRef.current = interval;
  
    return () => {
      clearInterval(interval);
    };
  };
  
// Funzione per avviare la query e il polling
const handleStartQuery = async () => {
  console.log('handleStartQuery called, isXbrl:', isXbrl);
  console.log('Current isVisible before setting to false:', isVisible);

  // Nascondo i risultati esistenti
  setIsVisible(false);
  console.log('Setting isVisible to false');
  
  // Aspetto che la transizione di fade out sia completata
  await new Promise(resolve => setTimeout(resolve, 300));
  console.log('Timeout completed');

  // Reset dei risultati precedenti (già presente nel tuo codice)
  setComparisonResult(null);
  setTestata(null);
  setDataReceived(false);
  setError(null);
  setProgress(0);

  // Verifica che i dati richiesti siano stati forniti
  if (!selectedSurveyType || !survey1 || !survey2) {
    console.log('Mancano i dati richiesti:', { selectedSurveyType, survey1, survey2 });
    setError('Please select a survey type and upload both surveys');
    return;
  }

  // Inizializza lo stato per il caricamento, errori e progresso
  setIsLoading(true);

  // Genera un ID univoco per la survey
  const clientGeneratedSurveyId = `client-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  setSurveyId(clientGeneratedSurveyId);

  // Avvia il polling dello stato di avanzamento
  startProgressPolling(clientGeneratedSurveyId);

  // Prepara i dati da inviare come formData
  const formData = new FormData();
  formData.append('survey_type', selectedSurveyType.survey_code);
  formData.append('survey1', survey1);
  formData.append('survey2', survey2);
  formData.append('client_survey_id', clientGeneratedSurveyId);

  console.log('Invio richiesta per comparazione delle surveys');

  try {
    // Invio della richiesta POST al backend
    console.log('Inviando richiesta POST a /api/compare-surveys/');
    const response = await axios.post('/api/compare-surveys/', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    console.log('Full response:', response);
    console.log('Ricevuta risposta da /api/compare-surveys/');
    const responseData = response.data;

    // Parsing della risposta JSON se necessario
    let parsedResponseData;
    try {
      parsedResponseData = typeof responseData === 'string' ? JSON.parse(responseData) : responseData;
    } catch (parseError) {
      console.error('Errore nel parsing della risposta JSON:', parseError);
      setError('Errore nella risposta del server: formato JSON non valido');
      setIsLoading(false);
      return;
    }

    // Gestione del survey_id
    if (parsedResponseData.survey_id) {
      console.log('Ricevuto survey_id:', parsedResponseData.survey_id);
      setSurveyId(parsedResponseData.survey_id);
    } else {
      console.warn('Nessun survey_id nella risposta');
    }

    // Gestione della testata
    if (parsedResponseData.testata) {
      console.log('Ricevuta testata:', parsedResponseData.testata);
      setTestata(parsedResponseData.testata);
    } else {
      console.warn('Nessuna testata nella risposta');
    }

    // Gestione dei dati di comparazione
    if (parsedResponseData.data && Array.isArray(parsedResponseData.data)) {
      console.log('Ricevuti i dati di comparazione, lunghezza:', parsedResponseData.data.length);

      // Pulisci i dati preservando i valori di tipo stringa
      const cleanedData = parsedResponseData.data.map(item => {
        const cleanedItem = {};
        for (const [key, value] of Object.entries(item)) {
          if (typeof value === 'string') {
            cleanedItem[key] = value;  // Mantieni le stringhe come sono
          } else if (typeof value === 'number') {
            cleanedItem[key] = isNaN(value) ? null : value;  // Converti NaN in null per i numeri
          } else {
            cleanedItem[key] = value;  // Mantieni altri tipi di valore come sono
          }
        }
        return cleanedItem;
      });

      console.log('Primi 5 elementi dei dati puliti:', cleanedData.slice(0, 5));

      setComparisonResult(cleanedData);
      setDataReceived(true);
      setProgress(100);
      console.log('Setting isVisible to true with new data');
      // Attendi un tick per assicurarti che il DOM sia aggiornato
      await new Promise(resolve => setTimeout(resolve, 50));

      setIsVisible(true);  // Riattiva la visibilità
    } else {
      console.warn('Nessun dato di comparazione nella risposta o formato non valido');
      setError('Nessun dato di comparazione ricevuto dal server o formato non valido');
    }

    console.log('Comparazione survey completata');
    setIsLoading(false);
  } catch (error) {
    console.error('Errore in handleStartQuery:', error);

    // Gestione degli errori dal server
    if (error.response) {
      console.error('Risposta del server:', error.response.data);
      setError('Errore nella comparazione delle surveys: ' + JSON.stringify(error.response.data));
    } else {
      setError('Errore nella comparazione delle surveys: ' + error.message);
    }

    setIsLoading(false);
    setProgress(0);
    setIsVisible(true);  // Riattiva la visibilità anche in caso di errore
    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
      pollIntervalRef.current = null;
    }
  }
};

  // Funzione per aggiornare la barra di progresso
  const ProgressBar = () => {
    const displayProgress = dataReceived ? 100 : progress;
    return displayProgress > 0 ? (
      <BootstrapProgressBar now={displayProgress} label={`${Math.round(displayProgress)}%`} />
    ) : null;
  };

  // Funzione per avviare il Progress Polling
  const startProgressPolling = (id) => {
    // Ferma qualsiasi polling precedente
    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
    }
  
    const pollInterval = setInterval(async () => {
      try {
        console.log('Sending progress request for survey ID:', id);
        const response = await axios.get(`/api/progress/${id}/`);
        console.log('Progress response:', response.data);
  
        const progressValue = response.data.progress || response.data;
        if (progressValue !== undefined) {
          setProgress(progressValue);
          if (progressValue >= 100) {
            console.log('Progress complete');
            clearInterval(pollIntervalRef.current);
            setIsLoading(false);
            pollIntervalRef.current = null;
          }
        } else {
          console.warn('Progress is undefined in response');
        }
      } catch (error) {
        console.error('Error in progress polling:', error);
        clearInterval(pollIntervalRef.current);
        setIsLoading(false);
        pollIntervalRef.current = null;
      }
    }, 2000);
  
    pollIntervalRef.current = pollInterval;
  };

  useEffect(() => {
    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
      }
    };
  }, []);

  
  return (
    <Container fluid className="mt-4">
      <Row className="mb-4">
        <Col md={6}>
          {/* Dropdown per la selezione del tipo di survey */}
          <div className="mb-3 d-flex align-items-center">
            <Dropdown>
              <Dropdown.Toggle 
                variant="primary" 
                id="dropdown-survey-type" 
                style={surveyTypeButtonStyle}
              >
                {selectedSurveyType ? selectedSurveyType.survey_description : 'Select Survey Type'}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: '300px' }}>
                {surveyTypes.length > 0 ? (
                  surveyTypes.map(type => (
                    <Dropdown.Item key={type.Id} onClick={() => handleSurveyTypeSelect(type)}>
                      {type.survey_description}
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item disabled>No survey types available</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {/* Input per il caricamento dei file */}
          <div className="mb-3 d-flex align-items-center">
            <input
              type="file"
              onChange={(e) => handleFileUpload(e, 1)}
              style={{ display: 'none' }}
              id="survey1-upload"
            />
            <Button 
              as="label" 
              htmlFor="survey1-upload" 
              variant="outline-primary" 
              style={surveyTypeButtonStyle}
            >
              Browse Survey 1
            </Button>
            <span className="ms-2 text-truncate">{survey1 ? survey1.name : 'No file selected'}</span>
          </div>

          <div className="d-flex align-items-center">
            <input
              type="file"
              onChange={(e) => handleFileUpload(e, 2)}
              style={{ display: 'none' }}
              id="survey2-upload"
            />
            <Button 
              as="label" 
              htmlFor="survey2-upload" 
              variant="outline-primary" 
              style={surveyTypeButtonStyle}
            >
              Browse Survey 2
            </Button>
            <span className="ms-2 text-truncate">{survey2 ? survey2.name : 'No file selected'}</span>
          </div>
        </Col>

        <Col md={6} className="d-flex flex-column align-items-end">
          {/* Pulsante per avviare la query */}
          <Button 
            onClick={handleStartQuery} 
            variant="success" 
            disabled={isLoading || !selectedSurveyType || !survey1 || !survey2}
            className="mb-2"
            style={{ ...buttonStyle, width: '145px' }}  // Imposta la larghezza desiderata
            >
            {isLoading ? <Spinner animation="border" size="sm" /> : 'Start Query'}
          </Button>

          {/* Pulsante per l'esportazione dei risultati */}
          <Export 
            filename='Confronto_base'
            data={comparisonResult || []}
            testata={testata || []}
            disabled={!comparisonResult || comparisonResult.length === 0}
            style={buttonStyle}
          />
        </Col>
      </Row>

      {/* Alert per eventuali errori */}
      {error && <Alert variant="danger">{error}</Alert>}

      {/* Mostra la progress bar durante il caricamento */}
      {progress > 0 && (
        <Row className="mb-4">
          <Col>
            <ProgressBar now={progress} label={`${Math.round(progress)}%`} />
          </Col>
        </Row>
      )}

      {/* Tabella dei risultati. Record testata. */}
      {testata && (
        <Row className="mb-4">
          <Col>
            <Table striped bordered hover size="sm" style={{ fontSize: '0.8rem' }}>
              <thead>
                <tr style={{ fontSize: '0.75rem' }}>
                  <th>File</th>
                  <th>XbrlSchemaRef</th>
                  <th>EntityID</th>
                  <th>Taxonomy</th>
                  <th>FileName</th>
                  <th>Competenza</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: '0.75rem' }}>
                {Array.isArray(testata) ? (
                  testata.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.XbrlSchemaRef}</td>
                      <td>{item.EntityID}</td>
                      <td>{item.Taxonomy}</td>
                      <td>{item.FileName}</td>
                      <td>{item.Competenza}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>1</td>
                    <td>{testata.XbrlSchemaRef}</td>
                    <td>{testata.EntityID}</td>
                    <td>{testata.Taxonomy}</td>
                    <td>{testata.FileName}</td>
                    <td>{testata.Competenza}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}

      {/* Elemento di debug per visualizzare i dati ricevuti
      {comparisonResult && (
        <div>
          <h3>Debug: Comparison Result</h3>
          <pre>{JSON.stringify(comparisonResult.slice(0, 5), null, 2)}</pre>
        </div>
      )}*/}

      {/* Tabella dei risultati con fade effect */}
      {comparisonResult && comparisonResult.length > 0 && (
        <div className="table-container">
          <div 
            className={`fade-content ${isVisible ? 'fade-enter fade-enter-active' : 'fade-exit fade-exit-active'}`}
            key={comparisonResult.length} // Forza un re-render completo
          >
            <DTableSurveyComp 
              columns={Object.keys(comparisonResult[0])}
              data={comparisonResult}
              isXbrl={isXbrl}
              className="survey-table"
            />
          </div>
        </div>
      )}
      </Container>
    );
  };

export default CompareSurvey;
