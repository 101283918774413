export const CHANGED2 = '#fff9c4'
export const NEW2 = '#c8e6c9'
export const DELETED2 = '#ffebee'

export const CHANGED = '#FFC107'
export const NEW = '#4CAF50'
export const DELETED = '#F44336'

export const STATUS_NEW = 'NEW';
export const STATUS_DELETED = 'DELETED';
export const STATUS_CHANGED = 'CHANGED';