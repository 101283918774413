import React, { useState } from 'react';
import { Container, Button, Form, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { Export } from './Export';

const FinBil = () => {
  const [competenza, setCompetenza] = useState('');
  const [competenzaAP, setCompetenzaAP] = useState('');
  const [files, setFiles] = useState({
    'ATTIVO (T)': null,
    'ATTIVO (T-1)': null,
    'BASE3 (T)': null,
    'BASE3 (T-1)': null,
    'BASE4 (T)': null,
    'BASE4 (T-1)': null,
    'COGE (T)': null,
    'COGE (T-1)': null,
    'TITOLI (T)': null,
    'TITOLI (T-1)': null,
    'RACCORDI': null
  });
  const [resultData, setResultData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event, key) => {
    const file = event.target.files[0];
    if (file) {
      setFiles(prevFiles => ({ ...prevFiles, [key]: file }));
    }
  };

  const handleStartQuery = async () => {
    setIsLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('competenza', competenza);
    formData.append('competenzaAP', competenzaAP);
    
    Object.entries(files).forEach(([key, file]) => {
      if (file) {
        formData.append(key, file);
      }
    });

    try {
      const response = await fetch('/api/process-finbil/', {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      setResultData(data);
    } catch (error) {
      console.error('Error processing query:', error);
      setError('Error processing query. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderFileInput = (key) => (
    <Form.Group className="mb-4">
      <div className="text-white p-2 rounded mb-2" style={{ backgroundColor: '#C95E15' }}>
      <Form.Label className="fw-bold mb-0 small" style={{ fontSize: '0.85rem' }}>
          {key}
        </Form.Label>
      </div>
      <div className="d-flex align-items-center gap-2">
        <input
          type="file"
          onChange={(e) => handleFileChange(e, key)}
          style={{ display: 'none' }}
          id={`file-${key}`}
        />
        <Button 
          as="label" 
          htmlFor={`file-${key}`}
          variant="outline-primary"
          style={{ width: '180px' }}
        >
          Browse {key}
        </Button>
        <span className="text-muted text-truncate">
          {files[key] ? files[key].name : 'No file selected'}
        </span>
      </div>
    </Form.Group>
  );

  return (
    <Container fluid className="p-4">
      {/* Actions Bar */}
      <Row className="mb-4">
        <Col className="d-flex justify-content-end gap-3">
          <Button 
            onClick={handleStartQuery} 
            variant="success"
            disabled={isLoading}
            style={{ width: '160px' }}
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              'Start Query'
            )}
          </Button>
          <Export 
            filename='FinBil_Result'
            data={resultData || []}
            disabled={!resultData}
            style={{ width: '160px' }}
          />
        </Col>
      </Row>

      <Row className="g-4">
        {/* Left Column */}
        <Col md={6}>
          <Form.Group className="mb-4">
            <div className="text-white p-2 rounded mb-2" style={{ backgroundColor: '#C95E15' }}>
            <Form.Label className="fw-bold mb-0" style={{ fontSize: '0.9rem' }}>
                Competenza (T)
              </Form.Label>
            </div>
            <Form.Control
              type="date"
              value={competenza}
              onChange={(e) => setCompetenza(e.target.value)}
            />
          </Form.Group>
          {renderFileInput('ATTIVO (T)')}
          {renderFileInput('BASE3 (T)')}
          {renderFileInput('BASE4 (T)')}
          {renderFileInput('COGE (T)')}
          {renderFileInput('TITOLI (T)')}
        </Col>

        {/* Right Column */}
        <Col md={6}>
          <Form.Group className="mb-4">
            <div className="text-white p-2 rounded mb-2" style={{ backgroundColor: '#C95E15' }}>
            <Form.Label className="fw-bold mb-0" style={{ fontSize: '0.9rem' }}>
                Competenza (T-1)
              </Form.Label>
            </div>
            <Form.Control
              type="date"
              value={competenzaAP}
              onChange={(e) => setCompetenzaAP(e.target.value)}
            />
          </Form.Group>
          {renderFileInput('ATTIVO (T-1)')}
          {renderFileInput('BASE3 (T-1)')}
          {renderFileInput('BASE4 (T-1)')}
          {renderFileInput('COGE (T-1)')}
          {renderFileInput('TITOLI (T-1)')}
        </Col>
      </Row>

      {/* RACCORDI (centered below) */}
      <Row className="mt-4 justify-content-center">
        <Col md={6}>
          {renderFileInput('RACCORDI')}
        </Col>
      </Row>

      {error && (
        <Row className="mt-4">
          <Col>
            <Alert variant="danger">{error}</Alert>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default FinBil;