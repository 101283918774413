import * as actions from './actionTypes'
import axios from 'axios'
import { variables } from '../../Variables';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

export const authStart = (username)=> {
    return{
        type :actions.AUTH_START,
        user: username
    }
}

export const authSuccess = (token, username, is_superuser, is_finrisk, is_finbil) => {
    return {
        type: actions.AUTH_SUCCESS,
        token: token,
        user: username,
        is_superuser: Boolean(is_superuser),  // Converto in boolean
        is_finrisk: Boolean(is_finrisk),      // Converto in boolean
        is_finbil: Boolean(is_finbil)         // Converto in boolean
    };
};

export const authFail = error=> {
    return{
        type :actions.AUTH_FAIL,
        error :error
    }
}

export const checkAuthTimeout = expTime => {
    return dispatch =>{
        setTimeout(()=>{
            dispatch(logout())
        }, expTime*1000)

    }
}

export const logout = () =>{
    localStorage.removeItem('user')
    localStorage.removeItem('is_superuser')
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('token')
    localStorage.removeItem('pagesHome')
    localStorage.removeItem('pagesComp')
    return {
        type: actions.AUTH_LOGOUT
    }
}

export const authLogin = (username, password, navigate) => {
    return dispatch => {
        console.log("Login started with username:", username);
        dispatch(authStart(username));
        
        axios.post(variables.API_URL + 'dj-rest-auth/login/', 
            {
                username: username,
                password: password
            },
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json'
                }
            }
        ).then(res => {
            console.log("Full login response:", res.data);
            const token = res.data.key;
            const is_superuser = Boolean(res.data.user.is_superuser);
            const is_finrisk = Boolean(res.data.user.is_finrisk);
            const is_finbil = Boolean(res.data.user.is_finbil);
            
            const expDate = new Date(new Date().getTime() + 3600 * 1000);
            localStorage.setItem('user', username);
            localStorage.setItem('expirationDate', expDate);
            localStorage.setItem('token', token);
            localStorage.setItem('is_superuser', is_superuser);
            localStorage.setItem('is_finrisk', is_finrisk);
            localStorage.setItem('is_finbil', is_finbil);
            
            dispatch(authSuccess(token, username, is_superuser, is_finrisk, is_finbil));
            navigate('/home');
            dispatch(checkAuthTimeout(3600));
        }).catch(error => {
            console.log("Full error object:", error);
            if (error.response) {
                console.log("Login failed with response data:", error.response.data);
                console.log("Login failed with status:", error.response.status);
                console.log("Login failed with headers:", error.response.headers);
                dispatch(authFail(error.response.data));
            } else if (error.request) {
                console.log("Login failed, no response received:", error.request);
                dispatch(authFail("No response received from server"));
            } else {
                console.log("Login failed, error message:", error.message);
                dispatch(authFail(error.message));
            }
        });
    };
};

export const authSignup = (username, email, password1, password2, key, navigate) => {
    return dispatch => {
        console.log("Signup started with username:", username);
        dispatch(authStart(username));

        // Usa la chiave fornita dall'utente, invece di generarne una
        return new Promise((resolve, reject) => {
            axios.post(variables.API_URL + 'dj-rest-auth/registration/', {
                username: username,
                email: email,
                password1: password1,
                password2: password2,
                key: key  // Usa la chiave passata come parametro
            }).then(res => {
                console.log("Signup success:", res.data);
                if (res.data && res.data.user) {
                    dispatch(authSuccess(res.data.user.username, res.data.user.email, false));
                    if (navigate) {
                        navigate('/login');
                    }
                    resolve(res.data);
                } else {
                    console.log("Unexpected response format:", res.data);
                    const error = new Error("Unexpected response format");
                    dispatch(authFail(error.message));
                    reject(error);
                }
            }).catch(error => {
                if (error.response) {
                    console.log("Signup failed with response data:", error.response.data);
                    dispatch(authFail(error.response.data));
                } else if (error.request) {
                    console.log("Signup failed, no response received:", error.request);
                    dispatch(authFail("No response received from server"));
                } else {
                    console.log("Signup failed, error message:", error.message);
                    dispatch(authFail(error.message));
                }
                reject(error);
            });
        });
    };
};

// Azione che viene chiamata per verificare se un utente é già autenticato
export const authCheckState = ()=>{
    return dispatch =>{
        const token = localStorage.getItem('token')
        const username = localStorage.getItem('user')
        const is_superuser = localStorage.getItem('is_superuser') === 'true'; //la lettura dei valori da local storage vengono convertiti correttamente in booleani
        const is_finrisk = localStorage.getItem('is_finrisk') === 'true';
        const is_finbil = localStorage.getItem('is_finbil') === 'true';

        if(token === undefined){
            dispatch(logout())
        }
        else{
            const expirationDate = new Date(localStorage.getItem('expirationDate'))
            if (expirationDate <= new Date()){
                dispatch(logout())
            }
            else{
                
                dispatch(authSuccess(token,username,is_superuser,is_finrisk, is_finbil))
                dispatch(checkAuthTimeout((expirationDate.getTime()-new Date().getTime()) / 1000))
            }
        }
    }
}