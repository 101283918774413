import { Component } from 'react';
import { Table, Pagination, Col, InputGroup, FormControl, Row, Dropdown, Toast, ToastContainer } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { RowDetail } from './RowDetail';

/**
 * DTable - Componente React per la visualizzazione di dati tabulari con funzionalità di
 * filtro, ordinamento, paginazione e ridimensionamento colonne.
 */

export class DTable extends Component {

    constructor(props) {
        super(props);
        console.log("Constructor DTable - props.data:", props.data);
        // Inizializzazione dei dati e dei filtri
        const initialData = Array.isArray(props.data) ? props.data : [];
        console.log("Constructor DTable - initialData:", initialData);

        const initialFilters = {};
        this.resizingColumn = null;

        // Creazione dei filtri iniziali basati sulle chiavi dei dati
        if (initialData.length > 0) {
            Object.keys(initialData[0]).forEach(key => {
                initialFilters[key] = '';
            });
        }
        // Stato iniziale del componente
        this.state = {
            filters: initialFilters,
            rawData: initialData,         // Dati originali non filtrati
            data: initialData,            // Dati correntemente visualizzati
            sliceStart: 0,                // Indice di inizio per la paginazione
            pageOptions: [15, 20, 30, 50], // Opzioni per elementi per pagina
            pageNum: 15,                  // Numero corrente di elementi per pagina
            activePage: 1,                // Pagina corrente
            showModal: false,             // Stato del modal per i dettagli
            modalData: [],                // Dati mostrati nel modal
            sort: '>',                    // Direzione dell'ordinamento
            prevSort: '',                 // Colonna precedentemente ordinata
            showToast: initialData.length === 0,
            hasError: false,
            errorInfo: null,
            columnWidths: {},             // Larghezze delle colonne
        };
    }

    /**
     * Gestisce la visibilità del toast
     */
    setShow = (show) => {
        this.setState({ showToast: show });
    }

    /**
     * Recupera le chiavi (nomi delle colonne) dai dati
     */
    getKeys = () => {
        if (this.props.data.length > 0) {
            return Object.keys(this.props.data[0]);
        } else {
            return [''];
        }
    }

    /**
     * Genera il testo placeholder per i filtri
     */
    returnPlaceholder = (k) => {
        return "Filter by " + k;
    }

    /**
     * Gestisce l'aggiornamento dei filtri e applica il filtraggio
     */
    onFilterUpdated = () => {
        try {
            const filteredData = this.state.rawData.filter(item => 
                Object.entries(this.state.filters).every(([key, filterValue]) => {
                    if (!filterValue) return true;
                    const itemValue = item[key];
                    return itemValue != null && 
                           itemValue.toString().toLowerCase().includes(filterValue.toLowerCase());
                })
            );

            this.setState({
                data: filteredData,
                activePage: 1,
                sliceStart: 0,
                showToast: filteredData.length === 0
            }, () => {
                // Callback solo dopo che il filtro è stato applicato
                if (typeof this.props.callBack === 'function') {
                    this.props.callBack(filteredData);
                }
            });
        } catch (error) {
            console.error("Error in onFilterUpdated:", error);
            this.setState({ hasError: true, errorInfo: error });
        }
    }

    /**
     * Gestisce il ridimensionamento delle colonne
     */
    handleColumnResize = (index, newWidth) => {
        this.setState(prevState => ({
            columnWidths: {
                ...prevState.columnWidths,
                [index]: newWidth
            }
        }));
    }

    /**
     * Inizia il processo di ridimensionamento della colonna
     */
    handleResizeStart = (e, index) => {
        this.resizingColumn = index;
        this.startX = e.pageX;
        this.startWidth = this.state.columnWidths[index] || 100;
        document.addEventListener('mousemove', this.handleResizeMove);
        document.addEventListener('mouseup', this.handleResizeEnd);
    }
    
    /**
     * Gestisce il movimento durante il ridimensionamento
     */
    handleResizeMove = (e) => {
        if (this.resizingColumn !== null) {
            const diff = e.pageX - this.startX;
            this.handleColumnResize(this.resizingColumn, Math.max(this.startWidth + diff, 50));
        }
    }
    
    /**
     * Termina il processo di ridimensionamento
     */
    handleResizeEnd = () => {
        this.resizingColumn = null;
        document.removeEventListener('mousemove', this.handleResizeMove);
        document.removeEventListener('mouseup', this.handleResizeEnd);
    }

    /**
     * Gestisce i cambiamenti nei campi di filtro
     */
    handleFilterChange = (event) => {
        event.persist();
        const { id, value } = event.target;
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                [id]: value
            }
        }), () => {
            this.onFilterUpdated();
        });
    }

    /**
     * Inizializzazione al mount del componente
     */
    componentDidMount() {
        console.log("ComponentDidMount - this.props.data:", this.props.data);
        console.log("ComponentDidMount - this.state.data:", this.state.data);
        
        // Aggiorniamo lo stato solo se i dati sono effettivamente cambiati
        if (this.props.data !== this.state.rawData) {
            const newData = Array.isArray(this.props.data) ? this.props.data : [];
            this.setState({
                rawData: newData,
                data: newData,
                showToast: newData.length === 0
            }, () => {
                if (typeof this.props.callBack === 'function') {
                    this.props.callBack(newData);
                }
            });
        }
    }

    /**
     * Calcola l'indice finale per la slice della pagina corrente
     */
    getSliceEnd = () => {
        return this.state.sliceStart + this.state.pageNum;
    }

    /**
     * Restituisce il numero totale di elementi
     */
    getElementsNum = () => {
        return this.state.data.length;
    }

    /**
     * Controlla se il pulsante "precedente" deve essere disabilitato
     */
    getPrevStatus = () => {
        return this.state.activePage === 1;
    }

    /**
     * Controlla se il pulsante "successivo" deve essere disabilitato
     */
    getNextStatus = () => {
        return this.state.activePage * this.state.pageNum >= this.getElementsNum();
    }


    /**
     * Gestisce gli aggiornamenti delle props
     */
    static getDerivedStateFromProps(props, prevState) {
        console.log("getDerivedStateFromProps - props.data:", props.data);
        console.log("getDerivedStateFromProps - prevState.rawData:", prevState.rawData);
        
        // Verifichiamo che i dati siano effettivamente cambiati e non vuoti
        if (props.data !== prevState.rawData && Array.isArray(props.data) && props.data.length > 0) {
            return {
                rawData: props.data,
                data: props.data,
                filters: {},
                sort: '>',
                showToast: false
            };
        }
        return null;
    }

    /**
     * Gestisce gli errori nel componente
     */
    componentDidCatch(error, errorInfo) {
        this.setState({
            hasError: true,
            errorInfo: errorInfo
        });
        console.error("Error caught by componentDidCatch:", error, errorInfo);
    }

    /**
     * Gestisce la navigazione tra le pagine
     */
    shift = (e) => {
        this.setState((prevState) => {
            let newState = { ...prevState };
            if ('f' === e && !this.getNextStatus()) {
                newState.activePage = prevState.activePage + 1;
                newState.sliceStart = prevState.sliceStart + prevState.pageNum;
            } else if ('b' === e && !this.getPrevStatus()) {
                newState.activePage = prevState.activePage - 1;
                newState.sliceStart = prevState.sliceStart - prevState.pageNum;
            } else if ('first' === e && !this.getPrevStatus()) {
                newState.activePage = 1;
                newState.sliceStart = 0;
            } else if ('last' === e && !this.getNextStatus()) {
                const div = Math.floor(this.getElementsNum() / prevState.pageNum);
                const mod = this.getElementsNum() % prevState.pageNum;
                newState.activePage = div + (mod !== 0 ? 1 : 0);
                newState.sliceStart = mod !== 0 ? div * prevState.pageNum : this.getElementsNum() - prevState.pageNum;
            }
            return newState;
        }, () => {
            // Callback dopo l'aggiornamento della paginazione
            if (typeof this.props.callBack === 'function') {
                this.props.callBack(this.state.data.slice(this.state.sliceStart, this.getSliceEnd()));
            }
        });
    }

   /**
     * Gestisce l'ordinamento delle colonne
     */
   sort = (k) => {
    var data = this.state.data;
    if (this.state.prevSort === k) {
        data.sort((a, b) => (this.state.sort === '>' ? (a[k] > b[k] ? 1 : -1) : (a[k] < b[k] ? 1 : -1)));
        this.setState({ sort: (this.state.sort === '<' ? '>' : '<') });
    } else {
        data.sort((a, b) => (a[k] > b[k] ? 1 : -1));
        this.setState({ sort: '<', prevSort: k });
    }
}

    render() {
        console.log("Render - this.state.data:", this.state.data);

        if (this.state.hasError) {
            return <h1>Something went wrong. Please try refreshing the page.</h1>;
        }

        const dataToRender = Array.isArray(this.state.data) 
            ? this.state.data.slice(this.state.sliceStart, this.getSliceEnd()) 
            : [];

        console.log("Render - dataToRender:", dataToRender);

        return (
            <div>
                <ToastContainer position='bottom-end'>
                    <Toast bg='warning' onClose={() => this.setShow(false)} show={this.state.showToast} delay={10000} autohide>
                        <Toast.Header>
                            <strong className="me-auto">Warning</strong>
                        </Toast.Header>
                        <Toast.Body>Nothing was found, change parameters and retry.</Toast.Body>
                    </Toast>
                </ToastContainer>
                {dataToRender.length > 0 ? (
                    <>
                        <Table striped bordered size='sm'>
                        <thead>
                            <tr>
                                {this.getKeys().map((key, i) => {
                                    const baseStyle = {
                                        fontSize: this.props.size === 'sm' ? 10 : 12,
                                        width: this.state.columnWidths[i] || 'auto',
                                        position: 'relative'
                                    };

                                    const resizeHandle = (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                right: 0,
                                                top: 0,
                                                bottom: 0,
                                                width: '5px',
                                                cursor: 'col-resize'
                                            }}
                                            onMouseDown={(e) => this.handleResizeStart(e, i)}
                                        />
                                    );

                                    if (this.props.filtered !== undefined && this.props.filtered.toString().includes(key)) {
                                        return (
                                            <th key={i} style={baseStyle}>
                                                <div onClick={() => this.sort(key)}>
                                                    {key.replaceAll('_', ' ')}
                                                    <Icon.ArrowDown style={{ display: this.state.sort === '>' && this.state.prevSort === key ? 'inline-block' : 'none' }} />
                                                    <Icon.ArrowUp style={{ display: this.state.sort === '<' && this.state.prevSort === key ? 'inline-block' : 'none' }} />
                                                    <Icon.ArrowDownUp style={{ display: this.state.prevSort === key ? 'none' : 'inline-block' }} />
                                                </div>
                                                <InputGroup size='sm'>
                                                    <InputGroup.Text id="basic-addon1"><Icon.Filter /></InputGroup.Text>
                                                    <FormControl
                                                        type="text"
                                                        id={key}
                                                        placeholder={this.returnPlaceholder(key.replaceAll('_', ' '))}
                                                        style={{ fontSize: this.props.size === 'sm' ? 11 : 13 }}
                                                        value={this.state.filters[key] || ''}
                                                        onChange={this.handleFilterChange}
                                                    />
                                                </InputGroup>
                                                {resizeHandle}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <th key={i} style={baseStyle} className='align-top'>
                                                <div onClick={() => this.sort(key)}>
                                                    {key.replaceAll('_', ' ')}
                                                    <Icon.ArrowDown style={{ display: this.state.sort === '>' && this.state.prevSort === key ? 'inline-block' : 'none' }} />
                                                    <Icon.ArrowUp style={{ display: this.state.sort === '<' && this.state.prevSort === key ? 'inline-block' : 'none' }} />
                                                    <Icon.ArrowDownUp style={{ display: this.state.prevSort === key ? 'none' : 'inline-block' }} />
                                                </div>
                                                {resizeHandle}
                                            </th>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                            <tbody style={{ textAlign: 'left', fontSize: this.props.size === 'sm' ? 9 : 11 }}>
                                {dataToRender.map((data, index) => (
                                    <tr key={index} onDoubleClick={() => this.setState({ showModal: true, modalData: data })}>
                                        <RenderRow data={data} keys={this.getKeys()} columnWidths={this.state.columnWidths} />
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Row>
                            <Col className='col-3' style={{ display: 'flex', justifyContent: 'left' }}>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="outline-secondary">
                                        {this.state.pageNum} elements per page
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {this.state.pageOptions.map((p, i) => (
                                            <Dropdown.Item key={i} onClick={() => this.setState({ pageNum: p })}>
                                                {p}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col className='col-6' style={{ display: 'flex', justifyContent: 'center' }}>
                                <Pagination>
                                    <Pagination.First disabled={this.getPrevStatus()} onClick={() => this.shift('first')} />
                                    <Pagination.Prev disabled={this.getPrevStatus()} onClick={() => this.shift('b')} />
                                    <Pagination.Item active>{this.state.activePage}</Pagination.Item>
                                    <Pagination.Next disabled={this.getNextStatus()} onClick={() => this.shift('f')} />
                                    <Pagination.Last disabled={this.getNextStatus()} onClick={() => this.shift('last')} />
                                </Pagination>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <div>
                        <p>No data available. Please adjust your filters or try again later.</p>
                        <p>Debug Info:</p>
                        <pre>
                            {JSON.stringify({
                                propsData: this.props.data,
                                stateData: this.state.data,
                                dataToRender: dataToRender
                            }, null, 2)}
                        </pre>
                    </div>
                )}
                <RowDetail 
                    data={this.state.modalData} 
                    show={this.state.showModal} 
                    callBack={v => this.setState({ showModal: v })} 
                />
            </div>
        );
    }
}

const RenderRow = ({ data, keys, columnWidths }) => {
    return keys.map((key, index) => {
        return (
            <td key={index} style={{
                minWidth: "2em",
                maxWidth: "10em",
                width: columnWidths[index] || 'auto',
                maxHeight: "100",
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
            }}>
                {data[key]}
            </td>
        );
    });
}