import { Component } from "react";
import { Modal,ListGroup } from "react-bootstrap";

export class RowDetail extends Component{

    constructor(props){
        super(props)
    }

    render(){
        return(
        <Modal className = 'modal' show = {this.props.show} onHide = {()=> this.props.callBack(false)}
        size="lg"
        scrollable
        aria-labelledby="contained-modal-title-vcenter"
        centered>
            <Modal.Header closeButton>
                <Modal.Title>Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <ListGroup variant = 'flush'>
                {
                    Object.keys(this.props.data).map((k)=> {
                        //console.log(k)
                    return <ListGroup.Item>
                            <div>
                                <p style= {{fontWeight : 700}}>{k.replaceAll('_',' ')}</p>
                                <p>{this.props.data === undefined || this.props.data[k] === undefined ||
                                this.props.data[k] === null || this.props.data[k] === ' ' ? '-' : this.props.data[k].toString().replaceAll('||',' || ')}</p>
                            </div>
                        </ListGroup.Item>
                })}
                
            </ListGroup>
            </Modal.Body>
        </Modal>
        )}
}