import React from 'react';

const FinRisk = () => {
  return (
    <div>
      <h1>FinRisk Component</h1>
      {/* Aggiungi il contenuto del componente qui */}
    </div>
  );
};

export default FinRisk;