import React, { Component } from 'react';
import { Table, Container } from 'react-bootstrap';
import { variables } from '../Variables';

// Stile in linea per il testo più piccolo
const smallTextStyle = {
    fontSize: '0.8rem'  // Posso regolare questo valore secondo le preferenze
  };

export class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            error: null, // Stato per gestire eventuali errori
        };
        console.log("Users component constructed");
    }

    // Funzione per recuperare la lista utenti dall'API
    refreshList() {
      fetch('/api/users/', { 
          method: 'GET',
          credentials: 'include'  // manteniamo questo che è già presente
      })
      .then(response => {
          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
      })
      .then(data => {
          this.setState({ users: data, error: null });
      })
      .catch(error => {
          console.error('Errore durante il recupero degli utenti:', error);
          this.setState({ error: 'Errore nel recupero degli utenti' });
      });
  }

    componentDidMount() {
      console.log("Users component mounted");
        this.refreshList();
    }

    render() {
      console.log("Users component rendering");
        const { users, error } = this.state;
        return (
          <Container>
            <h2>Lista Utenti</h2>
            {error ? (
              <div className="alert alert-danger">{error}</div>
            ) : users.length === 0 ? (
              <div>Nessun utente trovato.</div>
            ) : (
              <Table striped bordered hover className="small-text-table">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>User</th>
                    <th>Email</th>
                    <th>Key</th>
                    <th>Privilege</th>
                    <th>Finrisk</th>
                    <th>Finbil</th>
                    <th>Date Joined</th>
                    <th>Last Login</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map(user => (
                    <tr key={user.id} style={smallTextStyle}>
                      <td>{user.id}</td>
                      <td>{user.username}</td>
                      <td>{user.email}</td>
                      <td>{user.key}</td>
                      <td>{user.is_superuser ? 'SuperUser' : 'StandardUser'}</td>
                      <td>{user.is_finrisk ? 'Sì' : 'No'}</td>
                      <td>{user.is_finbil ? 'Sì' : 'No'}</td>
                      <td>{user.date_joined}</td>
                      <td>{user.last_login || 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Container>
        );
      }
    }

export default Users;
