import React, { Component } from 'react';
import { Table, Button, Pagination, Col, InputGroup, FormControl, Row, Dropdown, Form } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { RowCompare } from './RowCompare';

// Definisce i nuovi colori per i nuovi stati
const STATUS_COLORS = {
    BASE1: '#808080',  // Grigio
    BASE2: '#007bff',  // Blu
    NO_DIFF: '28a745', // Verde
    DIFF: '#ffc107'    // Giallo
};

{/* Componente di debug separato che mostra informazioni chiave
const DebugInfo = ({ data, filters, activePage }) => (
    <div style={{ background: '#f0f0f0', padding: '10px', margin: '10px 0' }}>
      <h4>Debug Info</h4>
      <p>Total items: {data.length}</p>
      <p>Active filters: {JSON.stringify(filters, null, 2)}</p>
      <p>Current page: {activePage}</p>
      <p>First 5 items: {JSON.stringify(data.slice(0, 5), null, 2)}</p>
    </div>
  );*/}


export class DTableSurveyComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: {},
            rawData: Array.isArray(props.data) ? props.data : [],
            data: Array.isArray(props.data) ? props.data : [],
            sliceStart: 0,
            pageOptions: [15, 20, 30, 50],
            pageNum: 15,
            activePage: 1,
            showModal: false,
            modalData: [],
            sort: '>',
            prevSort: '',
            dataUnchanged: [],
            dataChanged: [],
            dataNew: [],
            dataDeleted: [],
            compareWith: { name: '' },
            dbName: '',
            columnWidths: {},
            isResizing: false,
            lastX: 0,
            base1:true,
            base2:true,
            noDiff:true,
            diff:true,
            isXbrl: props.isXbrl || false,
            sortedColumn: null,
            sortOrder: 'asc'            
        };
        this.tableRef = React.createRef();
        console.log("DTableSurveyComp constructor - Initial state:", this.state);
        console.log("DTableSurveyComp constructor - Received props:", props);
        console.log("DTableSurveyComp constructor - Initial props:", JSON.stringify(props, null, 2));
    }

    // Ottiene le chiavi dei dati
    getKeys = () => {
        const keys = this.props.keys || (this.props.data.length > 0 ? Object.keys(this.props.data[0]) : []);
        console.log("getKeys - Chiavi Ottenute:", keys);
        return keys;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            console.log("DTableSurveyComp received new data:", this.props.data);
            this.initializeData(this.props.data);
        }
        
        if (JSON.stringify(prevState.filters) !== JSON.stringify(this.state.filters)) {
            console.log("Filters changed:", this.state.filters);
            this.onFilterUpdated();
        }
    
        // Aggiungo la nuova logica per gestire i cambiamenti nei filtri
        if (prevState.new !== this.state.new ||
            prevState.changed !== this.state.changed ||
            prevState.deleted !== this.state.deleted) {
            this.onSelectionChange();
        }
    }
    
    // Inizializza i dati quando il componente viene montato
    componentDidMount() {
        console.log("DTableSurveyComp mounted with props:", this.props);
        if (this.props.data && this.props.data.length > 0) {
          this.initializeData(this.props.data);
        } else {
          // Mantieni il fetch solo se necessario
          fetch('/api/checkDiffs', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ /* request parameters */ })
          })
          .then(response => response.json())
          .then(data => {
            console.log("Dati ricevuti dal server:", JSON.stringify(data, null, 2));
            this.initializeData(data.data);
          })
          .catch(error => console.error('Errore nel recupero dei dati:', error));
        }
        this.initializeColumnWidths();
      }

    initializeColumnWidths = () => {
        const keys = this.getKeys();
        const columnWidths = {};
        keys.forEach(key => {
            columnWidths[key] = 150; // Larghezza iniziale di default
        });
        this.setState({ columnWidths });
    }

    handleMouseDown = (e, key) => {
        e.preventDefault();
        this.setState({
            isResizing: true,
            currentColumn: key,
            lastX: e.clientX
        });
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
    }

    handleMouseMove = (e) => {
        if (!this.state.isResizing) return;
        const { columnWidths, lastX, currentColumn } = this.state;
        const diff = e.clientX - lastX;
        this.setState({
            columnWidths: {
                ...columnWidths,
                [currentColumn]: Math.max(50, columnWidths[currentColumn] + diff)
            },
            lastX: e.clientX
        });
    }

    handleMouseUp = () => {
        this.setState({ isResizing: false });
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
    }

    initializeData(data) {
        console.log("Initializing data:", JSON.stringify(data.slice(0, 5), null, 2));
        if (!Array.isArray(data)) {
            console.error('Data is not an array:', data);
            return;
        }
      
        const processedData = data.map(item => ({
            ...item,
            status: item.status || 'NO_DIFF'
        }));
    
        let sortedData = processedData;
        if (this.state.isXbrl) {
            sortedData = this.sortXbrlData(processedData);
        }
    
        this.setState({
            rawData: sortedData,
            data: sortedData,
            base1: true,
            base2: true,
            noDiff: true,
            diff: true,
            sortedColumn: this.state.isXbrl ? 'Table_R1' : null,
            sortOrder: 'asc'
        }, () => {
            console.log("Stato dopo l'inizializzazione:", JSON.stringify(this.state, null, 2));
            this.onSelectionChange();
        });
    }

      sortXbrlData = (data) => {
        return [...data].sort((a, b) => {
            const fields = ['Table_R1', 'Row_R1', 'Column_R1', 'Sheet_R1'];
            for (let field of fields) {
                if (a[field] < b[field]) return -1;
                if (a[field] > b[field]) return 1;
            }
            return 0;
        });
    }

    handleSort = (key) => {
        const { data, sortedColumn, sortOrder, isXbrl } = this.state;
        let newOrder = 'asc';
    
        if (key === sortedColumn) {
            newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        }
    
        let sortedData;
        if (isXbrl && ['Table_R1', 'Row_R1', 'Column_R1', 'Sheet_R1'].includes(key)) {
            sortedData = this.sortXbrlData(data);
            if (newOrder === 'desc') {
                sortedData.reverse();
            }
        } else {
            sortedData = [...data].sort((a, b) => {
                if (a[key] < b[key]) return newOrder === 'asc' ? -1 : 1;
                if (a[key] > b[key]) return newOrder === 'asc' ? 1 : -1;
                return 0;
            });
        }
    
        this.setState({
            data: sortedData,
            sortedColumn: key,
            sortOrder: newOrder
        });
    }


    // Aggiorna i filtri e i dati visualizzati
    onFilterUpdated = () => {
        const filteredData = this.getSelected().filter(el => 
            Object.keys(this.state.filters).every(k => 
                el[k] && el[k].toString().toLowerCase().includes(this.state.filters[k].toLowerCase())
            )
        );

        this.setState({
            data: filteredData,
            activePage: 1,
            sliceStart: 0
        }, () => {
            console.log("onFilterUpdated - Filtered data:", filteredData);
        });
    }

    // Gestisce il cambiamento dei filtri
    handleFilterChange = (e) => {
        console.log("Filter changed:", e.target.id, e.target.value);
        const filters = { ...this.state.filters, [e.target.id]: e.target.value };
        this.setState({ filters }, this.onFilterUpdated);
    }

    // Ottiene l'indice di fine per la slice dei dati
    getSliceEnd = () => this.state.sliceStart + this.state.pageNum;

    // Ottiene il numero totale di elementi
    getElementsNum = () => this.state.data.length;

    // Controlla se il pulsante "precedente" dovrebbe essere disabilitato
    getPrevStatus = () => this.state.activePage === 1;

    // Controlla se il pulsante "successivo" dovrebbe essere disabilitato
    getNextStatus = () => this.state.activePage * this.state.pageNum >= this.getElementsNum();

    // Gestisce il cambio di pagina
    shift = (direction) => {
        let { activePage, sliceStart, pageNum } = this.state;
        console.log("Pagination shift:", { direction, newActivePage: activePage, newSliceStart: sliceStart });
        const totalPages = Math.ceil(this.getElementsNum() / pageNum);

        switch (direction) {
            case 'f':
                if (activePage < totalPages) {
                    activePage++;
                    sliceStart += pageNum;
                }
                break;
            case 'b':
                if (activePage > 1) {
                    activePage--;
                    sliceStart -= pageNum;
                }
                break;
            case 'first':
                activePage = 1;
                sliceStart = 0;
                break;
            case 'last':
                activePage = totalPages;
                sliceStart = (totalPages - 1) * pageNum;
                break;
        }

        this.setState({ activePage, sliceStart }, () => {
            console.log("shift - New page state:", { activePage, sliceStart });
        });
    }

    // Ottiene i dati selezionati in base ai filtri attivi
    getSelected = () => {
        const { base1, base2, noDiff, diff, rawData } = this.state;
        const selectedData = rawData.filter(item => {
          if (base1 && item.status === 'BASE1') return true;
          if (base2 && item.status === 'BASE2') return true;
          if (noDiff && item.status === 'NO_DIFF') return true;
          if (diff && item.status === 'DIFF') return true;
          return false;
        });
        console.log("Selected data:", selectedData.length, "items");
        return selectedData;
      }


    // Gestisce il cambiamento della selezione dei filtri
    onSelectionChange = () => {
        const selectedData = this.getSelected();
        this.setState(prevState => ({
            data: selectedData,
            activePage: 1,
            sliceStart: 0,
            filters: {},
            // Aggiorna lo stato dei filtri
            base1: prevState.base1,
            base2: prevState.base2,
            noDiff: prevState.noDiff,
            diff: prevState.diff
        }), () => {
            console.log("onSelectionChange - New state:", this.state);
        });
    }

    render() {
        {/*console.log("DTableSurveyComp render - Current state:", JSON.stringify(this.state, null, 2));
        console.log("DTableSurveyComp render - Data keys:", this.getKeys());
        console.log("Data being rendered:", JSON.stringify(this.state.data.slice(0, 5), null, 2));*/}
        const { data, sliceStart, pageNum, activePage, showModal, modalData, filters, columnWidths } = this.state;
        const keys = this.getKeys();  // Include tutte le chiavi, comprese quelle della primary key
        console.log("Data being rendered:", this.state.data.slice(0, 5)); // Mostra i primi 5 elementi
    
        return (
            <div>
                {/* Aggiunta del box descrittivo */}
                <div style={{
                    backgroundColor: 'white',
                    border: '1px solid #007bff',  // Cornice azzurrina
                    borderRadius: '5px',  // Bordo arrotondato
                    padding: '10px',  // Spaziatura interna
                    marginBottom: '10px',  // Spaziatura dal Form.Switch successivo
                    color: '#333',  // Colore del testo
                    fontSize: '16px',  // Dimensione del testo
                    fontWeight: 'bold',  // Testo in grassetto
                    textAlign: 'center',  // Allineamento del testo al centro
                    width: '5.3cm',  // Larghezza di 5.3 cm
                    marginLeft: '0'  // Allineamento a sinistra
                }}>
                    Filter Status
                </div>
                <Form className='col-2'>
                    <Form.Switch 
                        id='base1'
                        name='base1'
                        label='BASE1'
                        onChange={() => this.setState(prev => ({ base1: !prev.base1 }), this.onSelectionChange)}
                        checked={this.state.base1}
                        style={{ fontSize: '18px', transform: 'scale(0.9)', marginBottom: '5px' }}
                    />
                    <Form.Switch 
                        id='base2'
                        name='base2'
                        label='BASE2'
                        onChange={() => this.setState(prev => ({ base2: !prev.base2 }), this.onSelectionChange)}
                        checked={this.state.base2}
                        style={{ fontSize: '18px', transform: 'scale(0.9)', marginBottom: '5px' }}
                    />
                    <Form.Switch 
                        id='noDiff'
                        name='noDiff'
                        label='NO_DIFF'
                        onChange={() => this.setState(prev => ({ noDiff: !prev.noDiff }), this.onSelectionChange)}
                        checked={this.state.noDiff}
                        style={{ fontSize: '18px', transform: 'scale(0.9)', marginBottom: '5px' }}
                    />
                    <Form.Switch 
                        id='diff'
                        name='diff'
                        label='DIFF'
                        onChange={() => this.setState(prev => ({ diff: !prev.diff }), this.onSelectionChange)}
                        checked={this.state.diff}
                        style={{ fontSize: '18px', transform: 'scale(0.9)', marginBottom: '5px' }}
                    />
                </Form>
                   <div style={{ overflowX: 'auto' }}>
                    <Table bordered size='sm' striped ref={this.tableRef}>
                    <thead>
                    <tr>
                            <th style={{ width: '30px' }}></th>
                            {keys.map((key, i) => (
                                <th key={i} style={{ 
                                    width: columnWidths[key] || 150, 
                                    position: 'relative',
                                    fontSize: this.props.size === 'sm' ? 9 : 11
                                }}>
                                    <div 
                                        onClick={() => this.handleSort(key)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {key.replaceAll('_', ' ')}
                                        {this.state.sortedColumn === key ? (
                                            this.state.sortOrder === 'asc' ? <Icon.ArrowUp /> : <Icon.ArrowDown />
                                        ) : (
                                            <Icon.ArrowDownUp />
                                        )}
                                    </div>
                                    {this.props.filtered && this.props.filtered.includes(key) && (
                                        <InputGroup size='sm'>
                                            <InputGroup.Text><Icon.Filter /></InputGroup.Text>
                                            <FormControl
                                                type="text"
                                                id={key}
                                                placeholder={`Filter by ${key.replaceAll('_', ' ')}`}
                                                style={{ fontSize: this.props.size === 'sm' ? 9 : 11 }}
                                                value={filters[key] || ''}
                                                onChange={this.handleFilterChange}
                                            />
                                        </InputGroup>
                                    )}
                                    <div
                                        style={{
                                            position: 'absolute',
                                            right: 0,
                                            top: 0,
                                            bottom: 0,
                                            width: '5px',
                                            cursor: 'col-resize',
                                            userSelect: 'none'
                                        }}
                                        onMouseDown={(e) => this.handleMouseDown(e, key)}
                                    />
                                </th>
                            ))}
                        </tr>
                    </thead>
                        <tbody style={{ textAlign: 'left', fontSize: this.props.size === 'sm' ? 9 : 11 }}>
                            {data.slice(sliceStart, this.getSliceEnd()).map((rowData, index) => (
                                <tr key={index} onDoubleClick={() => this.setState({ showModal: true, modalData: rowData })}>
                                    <td style={{ width: '30px' }}>
                                        <Icon.CircleFill 
                                                style={{ 
                                                color: STATUS_COLORS[rowData.status] || '',
                                                display: 'inline-block',
                                                width: '16px',
                                                height: '16px',
                                                opacity: 1,
                                                verticalAlign: 'middle',
                                                marginRight: '5px'
                                            }}
                                        />
                                    </td>
                                    {keys.map((key, colIndex) => (
                                        <td key={colIndex} style={{ 
                                            width: columnWidths[key] || 150,
                                            minWidth: "2em", 
                                            maxWidth: columnWidths[key] || "10em", 
                                            maxHeight: "100", 
                                            textOverflow: 'ellipsis', 
                                            overflow: 'hidden', 
                                            whiteSpace: 'nowrap' 
                                        }}>
                                            {typeof rowData[key] === 'object' ? JSON.stringify(rowData[key]) : rowData[key]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <Row>
                    <Col className='col-3' style={{ display: 'flex', justifyContent: 'left' }}>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="outline-secondary">
                                {pageNum} elements per page
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {this.state.pageOptions.map((p, index) => (
                                    <Dropdown.Item key={index} onClick={() => this.setState({ pageNum: p, activePage: 1, sliceStart: 0 })}>
                                        {p}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col className='col-6' style={{ display: 'flex', justifyContent: 'center' }}>
                        <Pagination>
                            <Pagination.First disabled={this.getPrevStatus()} onClick={() => this.shift('first')} />
                            <Pagination.Prev disabled={this.getPrevStatus()} onClick={() => this.shift('b')} />
                            <Pagination.Item active>{activePage}</Pagination.Item>
                            <Pagination.Next disabled={this.getNextStatus()} onClick={() => this.shift('f')} />
                            <Pagination.Last disabled={this.getNextStatus()} onClick={() => this.shift('last')} />
                        </Pagination>
                    </Col>
                </Row>
                <RowCompare 
                    data={modalData}
                    tabid={this.props.tabid}
                    state={modalData.status}
                    show={showModal}
                    callBack={v => this.setState({ showModal: v })}
                    pk={keys}
                    keys={this.getKeys().filter(el => el.includes('_update'))}
                    compareWithName={this.state.compareWith.name}
                    selectedDBName={this.state.dbName}
                />
            </div>
        );
    }
}

export default DTableSurveyComp;