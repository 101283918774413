import React, { Component } from 'react';
import { Tab, Tabs, Container } from 'react-bootstrap';
import { DbNavigator } from './DbNavigator';
import * as Icon from 'react-bootstrap-icons';

export class TabDbNavWrapper extends Component {
    constructor(props) {
        super(props);
        const storedPages = this.getStoredPages();
        this.state = {
            pages: storedPages || [{
                info: -1,
                loaded: props.loaded,
                tabName: null,
                deleted: null
            }],
            limit: 7
        };
    }

    getStoredPages = () => {
        try {
            const storedPages = JSON.parse(localStorage.getItem('pagesDbNav'));
            return Array.isArray(storedPages) ? storedPages : null;
        } catch (error) {
            console.error('Error parsing pages from localStorage:', error);
            return null;
        }
    };

    getActivePages = () => {
        return this.state.pages.filter(page => page.deleted !== 1);
    }

    newTab(e) {
        let pages = this.getActivePages();
        if (pages.length < this.state.limit) {
            pages.push({ info: -1, loaded: this.props.loaded, tabName: null, filter: '', deleted: null });
            this.setState({ pages });
            let lp = this.getStoredPages();
            if (lp) {
                lp = lp.filter(page => page.deleted !== 1);
                lp.push({ info: -1, loaded: this.props.loaded, tabName: null, filter: '', deleted: null });
                localStorage.setItem('pagesDbNav', JSON.stringify(lp));
            }
        } else {
            alert('Maximum number of tabs reached');
        }
    }

    changeTabName = (name, i) => {
        let pages = [...this.state.pages];
        let lp = this.getStoredPages();
        if (lp && pages !== lp) {
            pages = lp;
        }
        let page = { ...pages[i], tabName: name };
        pages[i] = page;
        this.setState({ pages });
        localStorage.setItem('pagesDbNav', JSON.stringify(pages));
    }

    closeTab(i) {
        let pages = this.getStoredPages() || this.state.pages;
        pages[i].deleted = 1;
        localStorage.setItem('pagesDbNav', JSON.stringify(pages));
        this.setState({ pages });
    }

    render() {
        const activePages = this.getActivePages();

        return (
            <Container fluid key='DbNavTabContainer'>
                <Tabs key='DbNavTabs' defaultActiveKey="dbNav0" id="uncontrolled-tab-example" className="mb-3">
                    {activePages.length > 0 ? (
                        activePages.map((i, p) => (
                            <Tab
                                key={'dbNav' + p}
                                eventKey={'dbNav' + p}
                                title={(
                                    <span>
                                        {i.tabName ? i.tabName : 'Tab ' + (p + 1)}
                                        {activePages.length > 1 && (
                                            <span className='ps-3'>
                                                <a variant='light' onClick={() => this.closeTab(p)}>
                                                    <Icon.X height='1.8em' width='1.8em' style={{ padding: -10, margin: -10 }} />
                                                </a>
                                            </span>
                                        )}
                                    </span>
                                )}
                            >
                                <DbNavigator {...this.props} loaded={i.loaded} changeName={this.changeTabName} id={p} />
                            </Tab>
                        ))
                    ) : (
                        <p>No tabs available</p>
                    )}
                    <Tab key='dbNavAdd' title={<a variant='link' onClick={(e) => this.newTab(e)}><Icon.Plus height='1.8em' width='1.8em' /></a>} />
                </Tabs>
            </Container>
        );
    }
}
