import React, { createContext, useContext, useEffect } from 'react';

// Creiamo il contesto
export const DbContext = createContext();

// Hook personalizzato per utilizzare il contesto
export const useDb = () => useContext(DbContext);

// Provider component
// Nota: Ora accettiamo 'value' come prop invece di gestire lo stato internamente
export const DbProvider = ({ children, value }) => {
  console.log("DbProvider: Component created with value:", value);

  // Effetto per loggare i cambiamenti del valore
  useEffect(() => {
    console.log("DbProvider: Value changed:", value);
  }, [value]);

  // Rendiamo il Provider con il valore del contesto fornito da App.js
  return <DbContext.Provider value={value}>{children}</DbContext.Provider>;
};

// Nota: Abbiamo rimosso il reducer, lo stato locale, fetchDbs, e setSelectedDbWithLog
// Questi sono ora gestiti in App.js e passati come parte del 'value'