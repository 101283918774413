import React, { Component } from 'react';
import { Table, Button, Pagination, Col, InputGroup, FormControl, Row, Dropdown, Form } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { RowCompare } from './RowCompare';
import { CHANGED, NEW, DELETED, STATUS_NEW, STATUS_DELETED, STATUS_CHANGED } from './colors';

// Funzione di utilità per determinare il colore di una riga basato sui suoi dati
const getColor = (data) => {
    if (data.status === 'New') return STATUS_NEW;
    if (data.status === 'Deleted') return STATUS_DELETED;
    if (data.status === 'Changed') return STATUS_CHANGED;
    return '';
};

export class DTableComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: {},
            rawData: Array.isArray(props.data) ? props.data : [],
            data: Array.isArray(props.data) ? props.data : [],
            sliceStart: 0,
            pageOptions: [15, 20, 30, 50],
            pageNum: 15,
            activePage: 1,
            showModal: false,
            modalData: [],
            sort: '>',
            prevSort: '',
            dataUnchanged: [],
            dataChanged: [],
            dataNew: [],
            dataDeleted: [],
            new: true,
            deleted: true,
            changed: true,
            compareWith: { name: '' },
            dbName: '',
        };
        console.log("DTableComp constructor - Initial state:", this.state);
        console.log("DTableComp constructor - Received props:", props);
    }

    // Ottiene le chiavi dei dati
    getKeys = () => {
        // Raccoglie tutte le possibili chiavi da tutti i record
        const allKeys = new Set();
        if (this.state.rawData && this.state.rawData.length > 0) {
            this.state.rawData.forEach(item => {
                if (item) {
                    Object.keys(item).forEach(key => allKeys.add(key));
                }
            });
        }
        const keysArray = Array.from(allKeys);
        console.log("getKeys - All available keys:", keysArray);
        return keysArray;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            console.log('Updated props data:', this.props.data);
            if (this.props.data.length > 0) {
                console.log('First item structure:', this.props.data[0]);
            }
            // Aggiungo questa chiamata per reinizializzare i dati quando cambiano
            this.initializeData(this.props.data);
        }
    
        // Aggiungo la nuova logica per gestire i cambiamenti nei filtri
        if (prevState.new !== this.state.new ||
            prevState.changed !== this.state.changed ||
            prevState.deleted !== this.state.deleted) {
            this.onSelectionChange();
        }
    }
    
    // Inizializza i dati quando il componente viene montato
    componentDidMount() {
        fetch('/api/checkDiffs', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ /* request parameters */ })
        })
        .then(response => response.json())
        .then(data => {
            console.log("Dati ricevuti dal server:", data);
            this.initializeData(data.data);
        })
        .catch(error => console.error('Errore nel recupero dei dati:', error));
    }

    initializeData(data) {
        if (!Array.isArray(data)) {
            console.error('Data is not an array:', data);
            return;
        }

        const processedData = data.map(item => ({
            ...item,
            status: item.status || 'Unchanged'  // Assicurati che ogni item abbia uno status
        }));

        this.setState({
            rawData: processedData,
            data: processedData,
            new: true,
            changed: true,
            deleted: true
        }, () => {
            console.log("Stato dopo l'inizializzazione:", this.state);
            this.onSelectionChange();  // Applica i filtri iniziali
        });
    }

    // Aggiorna i filtri e i dati visualizzati
    onFilterUpdated = () => {
        const filteredData = this.getSelected().filter(el => 
            Object.keys(this.state.filters).every(k => 
                el[k] && el[k].toString().toLowerCase().includes(this.state.filters[k].toLowerCase())
            )
        );

        this.setState({
            data: filteredData,
            activePage: 1,
            sliceStart: 0
        }, () => {
            console.log("onFilterUpdated - Filtered data:", filteredData);
        });
    }

    // Gestisce il cambiamento dei filtri
    handleFilterChange = (e) => {
        const filters = { ...this.state.filters, [e.target.id]: e.target.value };
        this.setState({ filters }, this.onFilterUpdated);
    }

    // Ottiene l'indice di fine per la slice dei dati
    getSliceEnd = () => this.state.sliceStart + this.state.pageNum;

    // Ottiene il numero totale di elementi
    getElementsNum = () => this.state.data.length;

    // Controlla se il pulsante "precedente" dovrebbe essere disabilitato
    getPrevStatus = () => this.state.activePage === 1;

    // Controlla se il pulsante "successivo" dovrebbe essere disabilitato
    getNextStatus = () => this.state.activePage * this.state.pageNum >= this.getElementsNum();

    // Gestisce il cambio di pagina
    shift = (direction) => {
        let { activePage, sliceStart, pageNum } = this.state;
        const totalPages = Math.ceil(this.getElementsNum() / pageNum);

        switch (direction) {
            case 'f':
                if (activePage < totalPages) {
                    activePage++;
                    sliceStart += pageNum;
                }
                break;
            case 'b':
                if (activePage > 1) {
                    activePage--;
                    sliceStart -= pageNum;
                }
                break;
            case 'first':
                activePage = 1;
                sliceStart = 0;
                break;
            case 'last':
                activePage = totalPages;
                sliceStart = (totalPages - 1) * pageNum;
                break;
        }

        this.setState({ activePage, sliceStart }, () => {
            console.log("shift - New page state:", { activePage, sliceStart });
        });
    }

    // Ordina i dati
    sort = (key) => {
        const { data, prevSort, sort } = this.state;
        let newSort = sort;

        if (prevSort === key) {
            newSort = sort === '>' ? '<' : '>';
        } else {
            newSort = '<';
        }

        const sortedData = [...data].sort((a, b) => {
            if (a[key] < b[key]) return newSort === '<' ? -1 : 1;
            if (a[key] > b[key]) return newSort === '<' ? 1 : -1;
            return 0;
        });

        this.setState({ data: sortedData, sort: newSort, prevSort: key }, () => {
            console.log("sort - Sorted data by key:", key);
        });
    }

    // Ottiene i dati selezionati in base ai filtri attivi
    getSelected = () => {
        const { changed, new: newData, unchanged, deleted, rawData } = this.state;
    
        return rawData.filter(item => {
            if (newData && item.status === 'New') return true;
            if (changed && item.status === 'Changed') return true;
            if (deleted && item.status === 'Deleted') return true;
            return false;
        });
    }


    // Gestisce il cambiamento della selezione dei filtri
    onSelectionChange = () => {
        const selectedData = this.getSelected();
        this.setState(prevState => ({
            data: selectedData,
            activePage: 1,
            sliceStart: 0,
            filters: {},
            // Aggiorna lo stato dei filtri
            new: prevState.new,
            changed: prevState.changed,
            deleted: prevState.deleted,
            unchanged: prevState.unchanged
        }), () => {
            console.log("onSelectionChange - New state:", this.state);
        });
    }


    render() {
        console.log("DTableComp render - Current state:", this.state);
        console.log("DTableComp render - Raw data example:", this.state.rawData[0]); // Mostra il primo record
        console.log("DTableComp render - Data keys:", this.getKeys());
        console.log("DTableComp render - Data content:", this.state.data);
        const allKeys = new Set();
        this.state.rawData.forEach(item => {
            Object.keys(item).forEach(key => {
                allKeys.add(key);
            });
        });
        console.log("DTableComp render - All possible keys:", Array.from(allKeys));
        
        const { data, sliceStart, pageNum, activePage, showModal, modalData, filters } = this.state;
        const keys = this.getKeys();
        console.log("DTableComp render - Keys used for table:", keys);

        return (
            <div>
                <Form className='col-2'>
                    <Form.Switch 
                        id='1'
                        name='new'
                        label='New'
                        onChange={() => this.setState(prev => ({ new: !prev.new }), this.onSelectionChange)}
                        checked={this.state.new}
                    />
                    <Form.Switch 
                        id='2'
                        name='changed'
                        label='Changed'
                        onChange={() => this.setState(prev => ({ changed: !prev.changed }), this.onSelectionChange)}
                        checked={this.state.changed}
                    />
                    <Form.Switch 
                        id='3'
                        name='deleted'
                        label='Deleted'
                        onChange={() => this.setState(prev => ({ deleted: !prev.deleted }), this.onSelectionChange)}
                        checked={this.state.deleted}
                    />
                </Form>
                <Table bordered size='sm' striped>
                    <thead>
                        <tr>
                            <th></th>
                            {keys.map((key, i) => (
                                <th key={i} style={{ fontSize: this.props.size === 'sm' ? 10 : 14 }}>
                                    <div onClick={() => this.sort(key)}>
                                        {key.replaceAll('_', ' ')}
                                        <Icon.ArrowDown style={{ display: this.state.sort === '>' && this.state.prevSort === key ? 'inline-block' : 'none' }} />
                                        <Icon.ArrowUp style={{ display: this.state.sort === '<' && this.state.prevSort === key ? 'inline-block' : 'none' }} />
                                        <Icon.ArrowDownUp style={{ display: this.state.prevSort === key ? 'none' : 'inline-block' }} />
                                    </div>
                                    {this.props.filtered && this.props.filtered.includes(key) && (
                                        <InputGroup size='sm'>
                                            <InputGroup.Text><Icon.Filter /></InputGroup.Text>
                                            <FormControl
                                                type="text"
                                                id={key}
                                                placeholder={`Filter by ${key.replaceAll('_', ' ')}`}
                                                style={{ fontSize: this.props.size === 'sm' ? 10 : 12 }}
                                                value={filters[key] || ''}
                                                onChange={this.handleFilterChange}
                                            />
                                        </InputGroup>
                                    )}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody style={{ textAlign: 'left', fontSize: this.props.size === 'sm' ? 10 : 12 }}>
                        {data.slice(sliceStart, this.getSliceEnd()).map((rowData, index) => (
                            <tr key={index} onDoubleClick={() => this.setState({ showModal: true, modalData: rowData })}>
                                <td>
                                    <Icon.CircleFill 
                                        style={{ 
                                            color: rowData.status === 'New' ? NEW :
                                                   rowData.status === 'Deleted' ? DELETED :
                                                   rowData.status === 'Changed' ? CHANGED : '',
                                            display: rowData.status === 'Unchanged' ? 'none' : 'inline-block',
                                            width: '16px',  // Aumenta questa dimensione per un pallino più grande
                                            height: '16px', // Aumenta questa dimensione per un pallino più grande
                                            opacity: 1,     // Questo assicura che il colore sia pieno
                                            verticalAlign: 'middle', // Allinea il pallino verticalmente al centro
                                            marginRight: '5px' // Aggiunge un po' di spazio a destra del pallino 
                                        }}
                                    />
                                </td>
                                {keys.map((key, colIndex) => (
                                    <td key={colIndex} style={{ 
                                        minWidth: "2em", 
                                        maxWidth: "10em", 
                                        maxHeight: "100", 
                                        textOverflow: 'ellipsis', 
                                        overflow: 'hidden', 
                                        whiteSpace: 'nowrap' 
                                    }}>
                                        {typeof rowData[key] === 'object' ? JSON.stringify(rowData[key]) : rowData[key]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Row>
                    <Col className='col-3' style={{ display: 'flex', justifyContent: 'left' }}>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="outline-secondary">
                                {pageNum} elements per page
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {this.state.pageOptions.map((p, index) => (
                                    <Dropdown.Item key={index} onClick={() => this.setState({ pageNum: p, activePage: 1, sliceStart: 0 })}>
                                        {p}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col className='col-6' style={{ display: 'flex', justifyContent: 'center' }}>
                        <Pagination>
                            <Pagination.First disabled={this.getPrevStatus()} onClick={() => this.shift('first')} />
                            <Pagination.Prev disabled={this.getPrevStatus()} onClick={() => this.shift('b')} />
                            <Pagination.Item active>{activePage}</Pagination.Item>
                            <Pagination.Next disabled={this.getNextStatus()} onClick={() => this.shift('f')} />
                            <Pagination.Last disabled={this.getNextStatus()} onClick={() => this.shift('last')} />
                        </Pagination>
                    </Col>
                </Row>
                <RowCompare 
                    data={modalData}
                    tabid={this.props.tabid}
                    state={modalData.status}
                    show={showModal}
                    callBack={v => this.setState({ showModal: v })}
                    pk={keys}
                    keys={this.getKeys().filter(el => el.includes('_update'))}
                    compareWithName={this.state.compareWith.name}
                    selectedDBName={this.state.dbName}
                    queryName={this.props.infoName}  // Passo il nome della query
                    dbNew={modalData.DATABASE_NEW}    // Uso il nome del database new
                    dbOld={modalData.DATABASE_OLD}    // Uso il nome del database old                
                />
            </div>
        );
    }
}

export default DTableComp;