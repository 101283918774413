import React,{Component} from 'react';
import { Tab,Tabs,Container, CloseButton} from 'react-bootstrap';
import { DbContext } from '../contexts/DbContext';
import { CompareDB } from './CompareDB';
import * as Icon from 'react-bootstrap-icons'


export class TabCompWrapper extends Component{
    static contextType = DbContext;

    constructor(props){
        super (props)
        if ( localStorage.getItem('pagesComp') !== null ){
            this.state = {
                pages :JSON.parse(localStorage.getItem('pagesComp')),
                limit:7 
            }    
        }
        else{
            this.state = {
                pages :[{
                    info : -1,
                    loaded :null,
                    compareWithId :-1,
                    compareWithName:'None',
                    tabName : null,
                    deleted : null
                }],
                limit:7 
            }
        }
    }

    componentDidMount() {
        // Aggiorna lo stato con i dati dal contesto
        const { loaded } = this.context;
        if (loaded) {
            this.setState(prevState => ({
                pages: prevState.pages.map(page => ({
                    ...page,
                    loaded: loaded
                }))
            }));
        }
    }

    newTab (e){
        let pages = this.state.pages
        pages =  pages.filter(page => page.deleted != 1)
        if (this.state.pages.length < this.state.limit){
            pages.push({info:-1, loaded:this.props.loaded, tabName:null,compareWithid :-1 , compareWithName:'None',deleted : null} )
            this.setState({pages:pages})
            let lp = JSON.parse(localStorage.getItem('pagesComp'))
            if (lp){
                lp = lp.filter(page => page.deleted != 1)
                lp.push({info:-1, loaded:this.props.loaded,tabName:null,compareWithId :-1, compareWithName:'None',deleted:null})
                localStorage.setItem('pagesComp',JSON.stringify(lp))
            }
        }
        else {
            alert('Maximum number of tabs reached')
        }
    }

    changeTabName = (name,i) => {
        let pages = [...this.state.pages];
        let lp = JSON.parse(localStorage.getItem('pagesComp'))
        if (lp && pages !== lp){
            pages = lp
        }
        let page = {
            ...pages[i],
            tabName: name
        }
        pages[i] = page
        this.setState({pages});
        localStorage.setItem('pagesComp', JSON.stringify(pages))
        console.log(pages)
    }

    applyChangeName (name,i) {

    }

    closeTab (e,i){
        let pages  = JSON.parse(localStorage.getItem('pagesComp')) ? 
        JSON.parse(localStorage.getItem('pagesComp')) :this.state.pages
        pages[i].deleted = 1
        localStorage.setItem('pagesComp',JSON.stringify(pages))
        this.setState({pages})
    }

    render() {
        const { dbs, loaded, updateDatabases } = this.context;

        return (
            <Container fluid key='compContainer'>
                <Tabs key='compTab' defaultActiveKey="comp0" id="uncontrolled-tab-example" className="mb-3">
                    {this.state.pages.map((i, p) => {
                        if (i.deleted === null) {
                            return (
                                <Tab key={"comp" + p} eventKey={"comp" + p} title={
                                    <span>{i.tabName ? i.tabName : 'Tab '+(p+1)} 
                                    {(this.state.pages.filter(page => page.deleted != 1).length == 1 ? '' :
                                    <span className='ps-3'><a variant='light' onClick={(e) => this.closeTab(e,p)}>
                                        <Icon.X height='1.8em' width='1.8em' style={{padding: -10, margin: -10}}/>
                                    </a></span>)}
                                    </span>
                                }>
                                    <CompareDB
                                        isAuthenticated={this.props.isAuthenticated}
                                        isSuperuser={this.props.isSuperuser}
                                        dbs={dbs}
                                        loaded={loaded}
                                        updateDatabases={updateDatabases}
                                        changeName={this.changeTabName}
                                        id={p}
                                    />
                                </Tab>
                            )
                        }
                        return null;
                    })}
                    <Tab key='compAdd' title={<a variant='link' onClick={e => this.newTab(e)}>
                        <Icon.Plus height='1.8em' width='1.8em' style={{padding: -10, margin: -10}}/>
                    </a>}></Tab>
                </Tabs>
            </Container>
        )
    }
}