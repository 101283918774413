import * as actions from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
    token:null,
    error:null,
    user: null,
    is_superuser: false,  // Cambiato da null a false
    pagesComp :[],
    pagesHome :[],
    is_finrisk: false,    // Cambiato da null a false
    is_finbil: false      // Cambiato da null a false
}

const authStart = (state,action) =>{
    return updateObject(state,{
        error :null,
        user :action.user
    })
}

const authSuccess = (state, action) =>{
    console.log("authSuccess - action:", action);
    return updateObject(state,{
        token :action.token,
        error :null,
        user:action.user,
        is_superuser :action.is_superuser,
        is_finrisk: action.is_finrisk,  // Aggiunto con nuova autorizzazione a finrisk
        is_finbil: action.is_finbil     // Aggiunto con nuova autorizzazione a finbil
    })
}

const authFail = (state, action) =>{
    return updateObject(state,{
        error :action.error
    })
}

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
        user: null,
        is_superuser: false,
        is_finrisk: false,
        is_finbil: false
    })
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.AUTH_START: return authStart(state, action)
        case actions.AUTH_SUCCESS: return authSuccess(state, action)
        case actions.AUTH_FAIL: return authFail(state, action)
        case actions.AUTH_LOGOUT: return authLogout(state, action)
        default: return state
    }
}

export default reducer